import React from 'react'
import { useThemeMode, Text } from '@rneui/themed'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
// Components
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { CustomBackgroundView } from '../../components/custom/CustomBackgroundView'
import { CustomCard } from '../../components/custom/CustomCard'
// Hooks
import { useMessageStore } from '../../redux/hooks/useMessage'
import { CloseSessionButton } from './Buttons'
import { ItemCard } from './ItemCard'

const images = [
  require('../../assets/img/soccer-player-001.png'),
  require('../../assets/img/soccer-player-001-dark.png'),
  require('../../assets/img/profile-color.png'),
]

const config = {
  title: 'Bienvenido',
  api: false,
  username: 1,
  firstname: 2,
  lastname: 3,
  clubId: 4,
}

export const Profile = ({ navigation }) => {
  const { setUserLogged, userLogged, setIsLogged, scoutingCounter } =
    useMessageStore()
  const { mode } = useThemeMode()

  const handleLogout = () => {
    setIsLogged(null)
    setUserLogged(null)
  }

  const handleNavigateToPlayer = (item, edit) => {
    navigation.navigate('MyProfilePlayer', { id: item.player.id, edit: edit })
  }

  return (
    <CustomBackgroundView mode={mode}>
      <CustomScrollView>
        {userLogged && userLogged[config.username] && (
          <>
            <Header title={'Bienvenido ' + userLogged[config.username]} />
            <View style={styles.imageContainer}>
              <Image style={styles.imageProfile} source={images[2]} />
            </View>
            <CloseSessionButton handler={handleLogout} />
            <View style={styles.containerCustomCard}>
              <Header title={'Tu Perfil'} />
              <CustomCard>
                <View style={styles.containerText}>
                  <Text style={styles.textLabel}>Usuario: </Text>
                  <Text> {userLogged[1]}</Text>
                </View>
                <View style={styles.containerText}>
                  <Text style={styles.textLabel}>Nombre y Apellido: </Text>
                  <Text>
                    {userLogged[config.firstname]} {userLogged[config.lastname]}
                  </Text>
                </View>
                <View style={styles.containerText}>
                  <Text style={styles.textLabel}>Club ID:</Text>
                  <Text> {userLogged[config.clubId]}</Text>
                </View>
              </CustomCard>
            </View>
            <Header title={'Jugadores en Scouting'} />
            <ItemCard
              user={userLogged[config.username]}
              handleNavigateToPlayer={handleNavigateToPlayer}
              scoutingCounter={scoutingCounter}
            />
          </>
        )}
      </CustomScrollView>
    </CustomBackgroundView>
  )
}

const styles = StyleSheet.create({
  imageContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  imageProfile: {
    width: 120,
    height: 120,
    resizeMode: 'cover',
    borderRadius: 15,
  },
  imageBottom: {
    width: '100%',
    height: 350,
    resizeMode: 'cover',
  },
  containerCustomCard: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  containerText: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  containerButtonAddPlayer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  text: {
    alignSelf: 'center',
  },
  textLabel: {
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  textTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 15,
    textAlign: 'center',
  },
  textCerrarSesion: {
    marginTop: 10,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
})

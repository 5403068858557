import React, { useState } from 'react'
import { useExplorePlayerByID } from '../../hooks/players/useExplorePlayerByID'
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { PhotoPlayerCard } from '../Explore/Player/PhotoPlayerCard/PhotoPlayerCard'
import { LogoClubCard } from '../../components/club/LogoClubCard'
import { DataPlayerCard } from '../Explore/Player/DataPlayerCard/DataPlayerCard'
import { ClubSkeleton } from '../../components/club/ClubSkeleton'
import { Image } from 'react-native'

const config = {
  title: 'Siguiendo',
}
export const HomePlayer = ({ navigation, route }) => {
  const { player } = useExplorePlayerByID(route.params.id)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleImageLoaded = () => {
    setIsImageLoaded(true)
  }
  return (
    <>
      <CustomScrollView>
        {isImageLoaded && (
          <>
            <Header title={config.title} />
            <PhotoPlayerCard
              id={route.params.id}
              name={player.name}
              photo={player.photo}
            />
            <LogoClubCard name={player.club} logo={player.logo} />
            <DataPlayerCard player={player} />
          </>
        )}
        <>
          {!isImageLoaded && <ClubSkeleton ancho="98%" />}
          <Image
            style={{ width: 1, height: 1, marginTop: 20 }}
            source={{ uri: player?.photo }}
            onLoad={handleImageLoaded}
          />
        </>
      </CustomScrollView>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Header } from '../../../components/layout/Header'
import { CustomScrollView } from '../../../components/custom/CustomScrollView'
import { PhotoPlayerCardApi } from './PhotoPlayerCard/PhotoPlayerCardApi'
import { DataPlayerCardApi } from './DataPlayerCard/DataPlayerCardFromApi'
import { PersonalDataCard } from './PersonalDataCard/PersonalDataCard'
import { useGetPlayer } from '../../../hooks/api/useGetPlayer'

const config = {
  title: 'Jugador',
}

export const PlayerApi = ({ route }) => {
  const { isLoading, player } = useGetPlayer(route.params.id)
  // const [sortedItems, setSortedItems] = useState(null)

  // useEffect(() => {
  //   if (player?.data?.statistics.length > 0) {
  //     let sorted = player.data.statistics.sort(
  //       (a, b) => parseInt(a.details.season_id) - parseInt(b.details.season_id)
  //     )
  //     console.log(sorted)
  //     setSortedItems(sorted)
  //   }
  // }, [player])

  return (
    <>
      <CustomScrollView>
        <Header title={config.title} />
        {!isLoading && player && (
          <PhotoPlayerCardApi
            id={route.params.id}
            name={player?.data?.common_name}
            photo={player?.data?.image_path}
          />
        )}
        {!isLoading && player && (
          <PersonalDataCard item={player?.data}></PersonalDataCard>
        )}
        {player &&
          player.data?.statistics?.map((item, index) => (
            <DataPlayerCardApi key={index} item={item} />
          ))}
      </CustomScrollView>
    </>
  )
}

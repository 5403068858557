import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { getTeams, getTeamsBySearchTerm } from '../../_json/api.team'
import { getPlayers, getPlayersBySearchTerm } from '../../_json/api.player'
import { useMessageStore } from '../../redux/hooks/useMessage'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const useExploreBySearchTerm = (search) => {
  const [searchDebounced] = useDebounce(search, 500)
  const [isLoading, SetIsLoading] = useState(true)
  const [teamList, setTeamList] = useState([])
  const [playerList, setPlayerList] = useState([])
  const [storePlayerList, setStorePlayerList] = useState([])
  const { userLogged, scoutingCounter } = useMessageStore()
  const STORAGE_KEY = () => userLogged[1] + '@storage_team'
  const getItem = async (key) => {
    return JSON.parse(await AsyncStorage.getItem(key))
  }

  useEffect(() => {
    const func = async () => {
      if (userLogged && userLogged[1]) {
        const response = await getItem(STORAGE_KEY())
        if (response)        
        setStorePlayerList((storePlayerList) => [
          ...storePlayerList,
          ...response
        ])
      }
    }
    func()
  }, [userLogged, scoutingCounter])

  useEffect(() => {
    setTeamList(getTeams())
    setPlayerList(getPlayers())
    SetIsLoading(false)
  }, [])

  const teamListFiltered = useCallback(
    getTeamsBySearchTerm(teamList, searchDebounced),
    [searchDebounced]
  )

  const playerListFiltered = useCallback(
    getPlayersBySearchTerm(playerList, searchDebounced),
    [searchDebounced]
  )

  const storePlayerListFiltered = useCallback(
    getPlayersBySearchTerm(storePlayerList, searchDebounced),
    [searchDebounced]
  )

  const listFiltered = useMemo(() => {
    const result = [
      ...teamListFiltered,
      ...playerListFiltered,
      ...storePlayerListFiltered
    ].map((item) => {
      return {
        id: item.player?.id || item.team.id,
        type: item.player?.id ? 'player' : 'team',
        title:
          item.player?.name ||
          (item.team.name && item.team.name + ' (' + item.team.code + ')'),
        subtitle1: item.player?.firstname
          ? item.player.firstname + ' ' + item.player.lastname
          : 'Country: ' + item.team?.country + '. Founded: ' + item.team?.founded,
        subtitle2:
          (item.statistics && 'Equipo: ' + item.statistics[0]?.team.name) ||
          null,
        image: item.player?.photo || item.team.logo
      }
    })

    return result
  }, [searchDebounced])

  return {
    isLoading,
    teamList,
    playerList,
    teamListFiltered,
    playerListFiltered,
    listFiltered
  }
}

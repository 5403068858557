import { useEffect, useState } from 'react'
import { Image } from 'react-native'

export const useImageCache = ({ source, isImageLoaded }) => {
  const [inCache, setInCache] = useState()

  useEffect(() => {
    const check = async () => {
      const result = await Image.queryCache([source])
      setInCache(result)
    }
    check()
  }, [isImageLoaded])

  return inCache
}

import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from '@rneui/themed'
import { useTheme } from '@rneui/themed'

export const Header = ({ title }) => {
  const { theme } = useTheme()

  return <Text style={styles.text(theme)}> {title} </Text>
}

const styles = StyleSheet.create({
  text: (theme) => ({
    fontSize: 20,
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    textAlign: 'center',
    paddingVertical: 5,
    marginBottom: 5,
    fontWeight: 'bold',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  })
})

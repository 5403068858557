import React, { useEffect, useRef } from 'react'
import { Animated } from 'react-native'

export const FadeInView = ({ style, children, update = true }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000, // Duración de la animación en milisegundos
      useNativeDriver: true
    }).start()
  }, [fadeAnim, update])

  return (
    <Animated.View style={{ ...style, opacity: fadeAnim }}>
      {children}
    </Animated.View>
  )
}

import React from 'react'
import { SafeAreaView, StyleSheet, Platform } from 'react-native'
import { useTheme } from '@rneui/themed'

export const CustomContainerView = ({ children }) => {
  const { theme } = useTheme()
  return <SafeAreaView style={styles.view(theme)}>{children}</SafeAreaView>
}

const styles = StyleSheet.create({
  view: (theme) => ({
    flex: 1,
    backgroundColor: theme.colors.background,
    ...Platform.select({
      ios: {
        alignSelf: 'auto'
      },
      android: {
        alignSelf: 'auto'
      },
      default: {
        width: '100%',
        maxWidth: 800,
        alignSelf: 'center'
      }
    })
  })
})

import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { CustomCard } from '../../../../components/custom/CustomCard'
import { useGetSeason } from '../../../../hooks/api/useGetSeason'
import { Card } from '@rneui/themed'
import { ligas, ligasObj } from './leagues'
import { CustomText } from './CustomText'

export const DataSeasonCard = ({ seasonId, details }) => {
  const { isLoading, season } = useGetSeason(seasonId)
  const id = season?.data?.league_id
  return (
    <>
      <CustomText>
        Temporada en {ligasObj[id]} {season?.data?.name}
      </CustomText>
      {details === true && (
        <>
          <CustomText>
            Es temporada actual: {season?.data?.is_current ? 'Si' : 'No'}
          </CustomText>
          <CustomText>
            Es Temporada Finalizada: {season?.data?.finished ? 'Si' : 'No'}
          </CustomText>
          <CustomText>
            Es Temporada Pendiente: {season?.data?.pending ? 'Si' : 'No'}
          </CustomText>
          <CustomText>
            Inicio de Temporada: {season?.data?.starting_at}
          </CustomText>
          <CustomText>
            Finalizacion de Temporada: {season?.data?.ending_at}
          </CustomText>
        </>
      )}
    </>
  )
}

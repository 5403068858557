import React from 'react'
import { Card } from '@rneui/themed'
import { View } from 'react-native'
import { CustomCard } from '../../../../components/custom/CustomCard'
import { CustomText } from './CustomText'

export const DataPlayerCard = ({ player }) => {
  return (
    <View>
      {player && (
        <>
          <CustomCard>
            <CustomText>Nombres: {player.firstname} </CustomText>
            <CustomText>Apellidos: {player.lastname} </CustomText>
            <CustomText>Edad: {player.age} </CustomText>
            <CustomText>Nacionalidad: {player.nationality}</CustomText>
            <CustomText>Fecha de Nacimiento: {player.birthDate} </CustomText>
            <CustomText>Lugar de Nacimiento: {player.birthPlace} </CustomText>
            <CustomText>Pais de Nacimiento: {player.birthCountry} </CustomText>
          </CustomCard>
          <CustomCard>
            <CustomText>Peso: {player.weight}</CustomText>
            <CustomText>Altura: {player.height}</CustomText>
            <CustomText>Lesionado: {player.injured}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Partidos Jugados</Card.Title>
            <CustomText>
              Game Appearences: {player.games?.appearences}
            </CustomText>
            <CustomText>Game Lineups: {player.games?.lineups}</CustomText>
            <CustomText>Game Minutes: {player.games?.minutes}</CustomText>
            <CustomText>Game Number: {player.games?.number}</CustomText>
            <CustomText>Game Position: {player.games?.position}</CustomText>
            <CustomText>Game Rating: {player.games?.rating}</CustomText>
            <CustomText>Game Captain: {player.games?.captain}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Substituciones</Card.Title>
            <CustomText>Substitutes In: {player.substitutes?.in}</CustomText>
            <CustomText>Substitutes Out: {player.substitutes?.out}</CustomText>
            <CustomText>
              Substitutes Bench: {player.substitutes?.bench}
            </CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Tiros al Arco</Card.Title>
            <CustomText>Shots Total: {player.shots?.total}</CustomText>
            <CustomText>Shots On: {player.shots?.on}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Goles</Card.Title>
            <CustomText>Goals Total: {player.goals?.total}</CustomText>
            <CustomText>Goals Conceded: {player.goals?.conceded}</CustomText>
            <CustomText>Goals Assists: {player.goals?.assists}</CustomText>
            <CustomText>Goals Saves {player.goals?.saves}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Pases</Card.Title>
            <CustomText>Passes Total: {player.passes?.total}</CustomText>
            <CustomText>Passes Key: {player.passes?.key}</CustomText>
            <CustomText>Passes Accuracy: {player.passes?.accuracy}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Tackles</Card.Title>
            <CustomText>Tackles Total: {player.tackles?.total}</CustomText>
            <CustomText>Tackles Blocks: {player.tackles?.blocks}</CustomText>
            <CustomText>
              Tackles Interceptions: {player.tackles?.interceptions}
            </CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Duels</Card.Title>
            <CustomText>Duels Total: {player.duels?.total}</CustomText>
            <CustomText>Duels Won: {player.duels?.won}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Dribbles</Card.Title>
            <CustomText>
              Dribbles Attempts: {player.dribbles?.attempts}
            </CustomText>
            <CustomText>
              Dribbles Success: {player.dribbles?.success}
            </CustomText>
            <CustomText>Dribbles Past: {player.dribbles?.past}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Faltas</Card.Title>
            <CustomText>Fouls Drawn: {player.fouls?.drawn}</CustomText>
            <CustomText>Fouls Committed: {player.fouls?.committed}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Tarjetas</Card.Title>
            <CustomText>Cards Yellow: {player.cards?.yellow}</CustomText>
            <CustomText>Cards YellowRed: {player.cards?.yellowred}</CustomText>
            <CustomText>Cards Red: {player.cards?.red}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Penalties</Card.Title>
            <CustomText>Penalty Won: {player.penalty?.won}</CustomText>
            <CustomText>
              Penalty Committed: {player.penalty?.committed}
            </CustomText>
            <CustomText>Penalty Scored: {player.penalty?.scored}</CustomText>
            <CustomText>Penalty Missed: {player.penalty?.missed}</CustomText>
            <CustomText>Penalty Saved: {player.penalty?.saved}</CustomText>
          </CustomCard>
          <CustomCard>
            <Card.Title>Recommendation</Card.Title>
            <CustomText>SCORE: {player.score}</CustomText>
          </CustomCard>
        </>
      )}
    </View>
  )
}

import React from 'react'
import { Platform, View, Text } from 'react-native'
import { FAB, makeStyles, useThemeMode } from '@rneui/themed'
import Icon from 'react-native-vector-icons/FontAwesome5'

export const ThemeButton = () => {
  const [visible, setVisible] = React.useState(true)
  const styles = useStyles()
  const { setMode, mode } = useThemeMode()

  const handleOnPress = () => {
    setMode(mode === 'dark' ? 'light' : 'dark')
  }

  return (
    <View style={styles.view}>
      <FAB
        size="small"
        containerStyle={styles.icon}
        visible={visible}
        icon={
          <Icon
            name={mode !== 'dark' ? 'moon' : 'lightbulb'}
            size={20}
            color={mode !== 'dark' ? 'white' : 'black'}
          />
        }
        color={mode === 'dark' ? 'white' : 'black'}
        onPress={handleOnPress}
        // style={{borderWidth: 1, borderColor: mode !== 'dark' ? 'black' : 'white'}}
      />
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  view: {    
    justifyContent: 'center',
    flexDirection: 'column',        
    ...Platform.select({
      ios: {
       height: '80%'
      },
      android: {
       height: '80%'
      },
      default: {
       height: '100%',
       marginRight: 30,
      }
    })
  },
  icon: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
}))

import React from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Text, Card } from '@rneui/themed'
import { LinearGradient } from 'expo-linear-gradient'
import { CustomCard } from '../../components/custom/CustomCard'
import { useExplorePlayerByScore } from '../../hooks/players/useExplorePlayerByScore'
import { useList } from '../../hooks/favorites/useList'

export const HorizontalCardRecomended = ({ handleNavigate }) => {
  const { isLoading: isLoadingSorted, playerListSortedIDS } =
    useExplorePlayerByScore()
  const { isLoading, list: data } = useList(playerListSortedIDS)
  return (
    <ScrollView
      style={styles.scrollView}
      contentContainerStyle={styles.containerScrollView}
      horizontal
      scrollEnabled
    >
      {!isLoading &&
        data?.length > 0 &&
        data?.map(
          (p) =>
            p?.id && (
              <TouchableOpacity key={p?.id} onPress={() => handleNavigate(p)}>
                <CustomCard>
                  <Card.Image
                    containerStyle={styles.containerImage}
                    style={styles.image}
                    source={{ uri: p?.image }}
                  ></Card.Image>
                  <LinearGradient
                    // Background Linear Gradient
                    colors={[
                      'transparent',
                      'transparent',
                      'rgba(0,0,0,0.25)',
                      'rgba(0,0,0,1)',
                    ]}
                    style={styles.gradient}
                  />
                  <Text style={styles.text1}>
                    {p?.title.length > 18
                      ? p?.title.substring(0, 18) + '..'
                      : p?.title}
                  </Text>
                  <Text style={styles.text2}>
                    {p?.title2.length > 18
                      ? p?.title2.substring(0, 18) + '..'
                      : p?.title2}
                  </Text>
                  <Text style={styles.text3}>Score: {p?.score}</Text>
                </CustomCard>
              </TouchableOpacity>
            ),
        )}
      {!isLoading && data.length === 0 && (
        <View
          style={{
            padding: 20,
            width: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ textAlign: 'center' }}>
            No estas siguiendo a ningun jugador
          </Text>
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    marginBottom: 5,
  },
  containerScrollView: {
    textAlign: 'center',
    minWidth: '100%',
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerImage: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  text1: {
    color: 'white',
    position: 'absolute',
    top: 120,
    fontSize: 10,
    width: 150,
    textAlign: 'center',
    fontWeight: '500',
  },
  text2: {
    color: 'white',
    position: 'absolute',
    top: 130,
    fontSize: 10,
    width: 150,
    textAlign: 'center',
    fontWeight: '500',
  },
  text3: {
    fontSize: 12,
    width: 150,
    textAlign: 'center',
  },
  image: {
    borderRadius: 5,
    width: 150,
    height: 150,
    overflow: 'hidden',
  },
  gradient: {
    width: 150,
    height: 60,
    overflow: 'hidden',
    position: 'absolute',
    top: 90,
    left: 0,
    boxShadow: 'inset 0px 0px 0px rgba(0,0,0,0.25)',
  },
})

import AsyncStorage from '@react-native-async-storage/async-storage'

const removeFromStorage = async (id, key) => {
  var data = JSON.parse(await AsyncStorage.getItem(key))
  if (data === null) data = []
  var _data = data.filter((item) => item != id)
  return _data
}

export const setItem = async (id, action, key) => {
  var data = await removeFromStorage(id, key)
  if (action === 'add') data.push(id)
  await AsyncStorage.setItem(key, JSON.stringify(data))
}

export const getItem = async (key) => {
  await AsyncStorage.getItem(key)
}

import React from 'react'
import { Avatar, ListItem } from '@rneui/themed'
import { useTheme } from '@rneui/themed'
import { StyleSheet, TouchableHighlight } from 'react-native'

export const RenderListItem = ({ item, handle }) => {
  const { theme } = useTheme()

  return (
    <>
      {item && (
        <TouchableHighlight
          underlayColor="rgba(0, 0, 0, 0.1)" // Color de opacidad al hacer clic
          onPress={() => handle(item)}
        >
          <ListItem
            containerStyle={styles.item(theme)}
            key={item.id}
            bottomDivider
            underlayColor="rgba(0, 0, 0, 0.05)"
          >
            <Avatar source={{ uri: item.image }} />
            <ListItem.Content>
              <ListItem.Title>{item.title}</ListItem.Title>
              <ListItem.Subtitle>{item.subtitle1}</ListItem.Subtitle>
              <ListItem.Subtitle>{item.subtitle2}</ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        </TouchableHighlight>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  item: (theme) => ({ backgroundColor: theme.colors.secondary })
})

import { useMessageStore } from '../../../../redux/hooks/useMessage'
import { useFavorites } from '../../../../hooks/favorites/useFavorites'

export const logica = (id, KEY) => {
  const { favoritesCounter, setFavoritesCounter } = useMessageStore()
  const { favorites, addFavorite, removeFavorite } = useFavorites(
    favoritesCounter,
    KEY
  )

  const handleFavorite = async () => {
    if (!favorites.includes(id)) {
      await addFavorite(id, KEY)
      setFavoritesCounter(favoritesCounter + 1)
    } else {
      await removeFavorite(id, KEY)
      setFavoritesCounter(favoritesCounter - 1)
    }
  }

  return {
    favorites,
    handleFavorite
  }
}

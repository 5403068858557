import teamsData from './data/laliga.json'
import { compareStrings } from '../helpers/stringHelper'

export const getTeams = () => {
  return teamsData.response
}

export const getTeamById = (id) => {
  return getTeams().filter((item) => id == item.team.id)
}

// Si searchTerm.length === 0 devuelve todos los equipos
// si searchTerm.length > 1 filtra los equipos segun el searchTerm
export const getTeamsBySearchTerm = (teams, searchTerm) => {
  return teams?.filter(
    (item) =>
      searchTerm.length === 0 ||
      (searchTerm?.length > 1 && compareStrings(searchTerm, item.team.name)),
  )
}

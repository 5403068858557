import React from 'react'
import { StyleSheet } from 'react-native'
import { Card } from '@rneui/themed'

export const CustomCard = (props) => {
  return <Card containerStyle={styles.container}>{props.children}</Card>
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,        
  }
})

import React from 'react'
import { useMessageStore } from '../../../redux/hooks/useMessage'
import { useExploreByID } from '../../../hooks/search/useExploreByID'
import { ClubScreen } from '../../../components/club/ClubScreen'

export const Club = ({ navigation }) => {
  const { club, setPlayer } = useMessageStore()
  const { teamListFiltered, playerListFiltered } = useExploreByID(club)

  const config = {
    title: 'Club',
    api: false,
    playerList: playerListFiltered ?? [],
    team: teamListFiltered[0].team ?? [],
    venue: teamListFiltered[0].venue ?? [],
  }

  const handleNavigateToPlayer = (item) => {
    setPlayer(item.player.id)
    navigation.navigate('Player', { id: item.player.id })
  }

  return (
    <ClubScreen
      handleNavigateToPlayer={handleNavigateToPlayer}
      config={config}
    />
  )
}

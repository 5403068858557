import React, { useState, useEffect } from 'react'
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { PhotoPlayerCardApi } from '../../screens/Explore/Player/PhotoPlayerCard/PhotoPlayerCardApi'
import { DataPlayerCardApi } from '../Explore/Player/DataPlayerCard/DataPlayerCardFromApi'
import { PersonalDataCard } from '../Explore/Player/PersonalDataCard/PersonalDataCard'
import { useGetPlayer } from '../../hooks/api/useGetPlayer'
import { ClubSkeleton } from '../../components/club/ClubSkeleton'
import { Image } from 'react-native'

const config = {
  title: 'Jugador',
}

export const HomePlayerApi = ({ route }) => {
  const { isLoading, player } = useGetPlayer(route.params.id)
  // const [sortedItems, setSortedItems] = useState(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const handleImageLoaded = () => {
    setIsImageLoaded(true)
  }

  // useEffect(() => {
  //   if (player?.data?.statistics.length > 0) {
  //     let sorted = player.data.statistics.sort(
  //       (a, b) => parseInt(a.details.season_id) - parseInt(b.details.season_id)
  //     )
  //     // console.log(sorted)
  //     setSortedItems(sorted)
  //   }
  // }, [player])

  return (
    <>
      <CustomScrollView>
        {isImageLoaded && (
          <>
            <Header title={config.title} />
            {!isLoading && player && (
              <PhotoPlayerCardApi
                id={route.params.id}
                name={player?.data?.common_name}
                photo={player?.data?.image_path}
              />
            )}
            {!isLoading && player && (
              <PersonalDataCard item={player?.data}></PersonalDataCard>
            )}
            {player &&
              player.data?.statistics?.map((item, index) => (
                <DataPlayerCardApi key={index} item={item} />
              ))}
          </>
        )}
        <>
          {!isImageLoaded && <ClubSkeleton ancho="98%" />}
          <Image
            style={{ width: 1, height: 1, marginTop: 20 }}
            source={{ uri: player?.data?.image_path }}
            onLoad={handleImageLoaded}
          />
        </>
      </CustomScrollView>
    </>
  )
}

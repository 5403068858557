import React from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import { Card } from '@rneui/themed'
import { LinearGradient } from 'expo-linear-gradient'
import { CustomCard } from '../../components/custom/CustomCard'
import { FadeInView } from '../../components/effect/FadeInView'
import { useIsImageLoaded } from '../../hooks/images/useIsImageLoaded'

export const PhotoPlayerCard = ({ name, photo }) => {
  const { isImageLoaded, handleImageLoaded, handleImageError } =
    useIsImageLoaded()
  return (
    <TouchableOpacity>
      <>
        {isImageLoaded && (
          <CustomCard>
            <Card.Title>{name}</Card.Title>
            <FadeInView style={{ flex: 1 }}>
              <View style={styles.imageView}>
                <Card.Image style={styles.image} source={{ uri: photo }}>
                  <LinearGradient
                    colors={['transparent', 'rgba(0,0,0,0.25)']}
                    style={styles.gradient}
                  />
                </Card.Image>
              </View>
            </FadeInView>
          </CustomCard>
        )}
        <>
          {!isImageLoaded && (
            <CustomCard>
              <Card.Title>{name}</Card.Title>
              <ActivityIndicator color="rgba(251,133,0,1)" size={100} />
            </CustomCard>
          )}
          <Card.Image
            style={{ width: 1, height: 1 }}
            source={{ uri: photo }}
            onLoadEnd={handleImageLoaded}
            onError={handleImageError}
          />
        </>
      </>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 15,
    right: 15,
    zIndex: 100,
  },
  imageView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: 75,
    width: 150,
    height: 150,
    overflow: 'hidden',
  },
  gradient: {
    borderRadius: 75,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: 'inset 0px 1px 2px rgba(0,0,0,0.25)',
  },
})

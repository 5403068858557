import React from 'react'
import { Avatar, ListItem, Text } from '@rneui/themed'
import { View, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useStorage } from '../../_storage-new-players/useStorage'
import { useTheme } from '@rneui/themed'

export const AddButton = ({ handleAddPlayer }) => {
  const { theme } = useTheme()

  return (
    <View
      style={{
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.colors.grey0,
      }}
    >
      <TouchableOpacity onPress={handleAddPlayer}>
        <Text
          style={{
            textAlign: 'center',
            lineHeight: 35,
            fontSize: 14,
            paddingLeft: 15,
          }}
        >
          Crear un Jugador
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          textAlign: 'center',
          lineHeight: 40,
          fontSize: 14,
        }}
        onPress={handleAddPlayer}
      >
        <Ionicons name="add" color={theme.colors.success} size={35} />
      </TouchableOpacity>
    </View>
  )
}

export const DeleteButton = ({ handleDeletePlayer, id }) => (
  <TouchableOpacity onPress={() => handleDeletePlayer(id)}>
    <Ionicons name="trash" color="red" size={24} />
  </TouchableOpacity>
)

export const EditButton = ({ handleNavigateToPlayer, item }) => {
  const { theme } = useTheme()
  return (
    <TouchableOpacity onPress={() => handleNavigateToPlayer(item, true)}>
      <Ionicons name="create" color={theme.colors.grey0} size={24} />
    </TouchableOpacity>
  )
}

export const ItemButton = ({
  handleNavigateToPlayer,
  handleDeletePlayer,
  item,
}) => {
  const { theme } = useTheme()

  return (
    <ListItem bottomDivider>
      <TouchableOpacity
        style={{
          gap: 10,
          flex: 1,
          flexDirection: 'row',
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderRadius: 5,
          borderColor: theme.colors.greyOutline,
          padding: 10,
        }}
        id={item.player.id}
        onPress={() => handleNavigateToPlayer(item, false)}
      >
        <Avatar
          source={{
            uri: item.player.photo,
          }}
        />
        <ListItem.Content>
          <ListItem.Title>{item.player.name}</ListItem.Title>
          <ListItem.Subtitle>
            {item.player.lastname} (id: {item.player.id})
          </ListItem.Subtitle>
        </ListItem.Content>
      </TouchableOpacity>
      <EditButton handleNavigateToPlayer={handleNavigateToPlayer} item={item} />
      <DeleteButton
        handleDeletePlayer={handleDeletePlayer}
        id={item.player.id}
      />
    </ListItem>
  )
}

export const CloseSessionButton = ({ handler }) => (
  <TouchableOpacity onPress={handler}>
    <Text style={{ marginTop: 10, alignSelf: 'center', fontWeight: 'bold' }}>
      ¿ Quieres cerrar sesion ? Haz clic aqui
    </Text>
  </TouchableOpacity>
)

import React, { useState } from 'react'
import { useStorage } from '../../_storage-new-players/useStorage'
import { CustomCard } from '../../components/custom/CustomCard'
import { View } from 'react-native'
import { Input, Button } from '@rneui/themed'
import { Formik } from 'formik'
import { Loader } from '../../components/loader/Loader'

export const Form = ({
  username,
  scoutingCounter,
  player,
  itemOriginal,
  handleNavigateToProfile,
}) => {
  const { handleUpdatePlayer, handleDeletePlayer } = useStorage(
    username,
    scoutingCounter,
  )

  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = async () => {
    setIsLoading(true)
    await handleDeletePlayer(player?.id)
    setIsLoading(false)
    handleNavigateToProfile()
  }

  var initialValues = {
    name: player?.name,
    firstname: player?.firstname,
    lastname: player?.lastname,
    age: player?.age,
    nationality: player?.nationality,
    birthDate: player?.birthDate,
    birthPlace: player?.birthPlace,
    birthCountry: player?.birthCountry,
    weight: player?.weight,
    height: player?.height,
    injured: player?.injured,
    comments: player?.comments,
    genre: player?.genre,
    team: player?.club,
    camiseta: player?.games?.number,
    playerPosition: player?.games?.position,
  }

  return (
    <CustomCard>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          setIsLoading(true)
          var objetoCopia = JSON.parse(JSON.stringify(itemOriginal))
          // console.log(objetoCopia)
          // console.log(values)
          objetoCopia.player.name = values.name
          objetoCopia.player.firstname = values.firstname
          objetoCopia.player.lastname = values.lastname
          objetoCopia.player.age = values.age
          objetoCopia.player.injured = values.injured
          objetoCopia.player.height = values.height
          objetoCopia.player.weight = values.weight
          objetoCopia.player.nationality = values.nationality
          objetoCopia.player.genre = values.genre
          objetoCopia.player.birth.date = values.birthDate
          objetoCopia.player.birth.place = values.birthPlace
          objetoCopia.player.birth.country = values.birthCountry
          objetoCopia.statistics[0].games.position = values.playerPosition
          objetoCopia.statistics[0].games.number = values.camiseta
          objetoCopia.statistics[0].team.name = values.team
          objetoCopia.player.comments = values.comments
          await handleUpdatePlayer(objetoCopia.player.id, objetoCopia)
          setIsLoading(false)
          handleNavigateToProfile()
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <>
            <Input
              label="Nombre Resumido"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Nombre Completo"
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              value={values.name}
            />
            <Input
              label="Nombres"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Nombres"
              onChangeText={handleChange('firstname')}
              onBlur={handleBlur('firstname')}
              value={values.firstname}
            />

            <Input
              label="Apellidos"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Apellidos"
              onChangeText={handleChange('lastname')}
              onBlur={handleBlur('lastname')}
              value={values.lastname}
            />

            <Input
              label="Edad"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Edad"
              onChangeText={handleChange('age')}
              onBlur={handleBlur('age')}
              value={values.age}
            />

            <Input
              label="Nacionalidad"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Nacionalidad"
              onChangeText={handleChange('nationality')}
              onBlur={handleBlur('nationality')}
              value={values.nationality}
            />

            <Input
              label="Fecha de Nacimiento"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Fecha de Nacimiento"
              onChangeText={handleChange('birthDate')}
              onBlur={handleBlur('birthDate')}
              value={values.birthDate}
            />

            <Input
              label="Lugar de Nacimiento"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Lugar de Nacimiento"
              onChangeText={handleChange('birthPlace')}
              onBlur={handleBlur('birthPlace')}
              value={values.birthPlace}
            />

            <Input
              label="País de Nacimiento"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="País de Nacimiento"
              onChangeText={handleChange('birthCountry')}
              onBlur={handleBlur('birthCountry')}
              value={values.birthCountry}
            />

            <Input
              label="Peso"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Peso"
              onChangeText={handleChange('weight')}
              onBlur={handleBlur('weight')}
              value={values.weight}
            />

            <Input
              label="Altura"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Altura"
              onChangeText={handleChange('height')}
              onBlur={handleBlur('height')}
              value={values.height}
            />

            <Input
              label="Lesionado"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Lesionado"
              onChangeText={handleChange('injured')}
              onBlur={handleBlur('injured')}
              value={values.injured}
            />
            <Input
              label="Genero"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Genero"
              onChangeText={handleChange('genre')}
              onBlur={handleBlur('genre')}
              value={values.genre}
            />
            <Input
              label="Equipo"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Equipo"
              onChangeText={handleChange('team')}
              onBlur={handleBlur('team')}
              value={values.team}
            />
            <Input
              label="Camiseta"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Camiseta"
              onChangeText={handleChange('camiseta')}
              onBlur={handleBlur('camiseta')}
              value={values.camiseta}
            />
            <Input
              label="Posicion"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Posicion"
              onChangeText={handleChange('playerPosition')}
              onBlur={handleBlur('playerPosition')}
              value={values.playerPosition}
            />
            <Input
              label="Comentarios"
              labelStyle={{ fontSize: 12, color: 'gray' }}
              placeholder="Comentarios"
              onChangeText={handleChange('comments')}
              onBlur={handleBlur('comments')}
              value={values.comments}
            />
            <View style={{ gap: 2, minHeight: 80 }}>
              <Loader isLoading={isLoading} />
              {!isLoading && (
                <>
                  <Button title="Guardar" onPress={handleSubmit} />
                  <Button title="Borrar" onPress={handleCancel} />
                </>
              )}
            </View>
          </>
        )}
      </Formik>
    </CustomCard>
  )
}

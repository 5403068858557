import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Text, Button } from '@rneui/themed'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { useExploreByID } from '../../hooks/search/useExploreByID'
import { SoccerSVG } from '../../components/svg/SoccerSVG'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { ClubScreen } from '../../components/club/ClubScreen'

export const MyClub = ({ navigation }) => {
  const { myClub, setMyPlayer, userLogged, isLogged } = useMessageStore()
  const { teamListFiltered, playerListFiltered } = useExploreByID(myClub?.club)

  const handleNavigateToLogin = () => {
    navigation.jumpTo('Perfil', { screen: 'Login' })
  }
  const handleNavigateToPlayer = (item) => {
    setMyPlayer(item.player.id)
    navigation.navigate('MyPlayer', { id: item.player.id })
  }

  const config = {
    title: 'My Club',
    api: false,
    playerList: playerListFiltered ?? [],
    team: teamListFiltered[0]?.team ?? undefined,
    venue: teamListFiltered[0]?.venue ?? undefined,
  }

  return (
    <>
      <CustomScrollView>
        <Text style={styles.text}>
          {' Hola! '}
          {!isLogged ? 'No estas logueado' : '¿Cómo estás '}
          {isLogged &&
            userLogged &&
            userLogged[1].charAt(0).toUpperCase() +
              userLogged[1].slice(1) +
              '?'}
        </Text>
        <SoccerSVG style={styles.svg} />
        {!isLogged && (
          <TouchableOpacity onPress={handleNavigateToLogin}>
            <Text style={styles.textIniciaSesion}>
              ¿Ya tienes una cuenta? ¡Inicia sesion!{' '}
            </Text>
          </TouchableOpacity>
        )}

        {isLogged && teamListFiltered?.length > 0 && (
          <>
            <ClubScreen
              handleNavigateToPlayer={handleNavigateToPlayer}
              config={config}
            />
          </>
        )}
      </CustomScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    lineHeight: 25,
    marginTop: 15,
    fontSize: 20,
    fontWeight: 'bold',
  },
  textIniciaSesion: {
    textAlign: 'center',
    lineHeight: 25,
  },
  svg: {
    alignSelf: 'center',
    marginBottom: 0,
    marginTop: 0,
  },
})

import React from 'react'
import { Avatar, ListItem } from '@rneui/themed'
import { useTheme } from '@rneui/themed'
import { StyleSheet, TouchableHighlight } from 'react-native'

export const RenderListItemFromApi = ({ item, handle }) => {
  const { theme } = useTheme()

  return (
    <>
      {item && (
        <TouchableHighlight
          underlayColor="rgba(0, 0, 0, 0.1)" // Color de opacidad al hacer clic
          onPress={() => handle(item)}
        >
          <ListItem style={styles.item(theme)} key={item.id} bottomDivider>
            <Avatar source={{ uri: item.image_path }} />
            <ListItem.Content>
              <ListItem.Title>
                {item.name}{' '}
                {item.type !== 'player' && item.short_code !== null && '(' + item.short_code + ')'}
              </ListItem.Title>
              <ListItem.Subtitle>
                {item.lastname}
                {item.type !== 'player' && ' Country: ' + item.country_id}
                {item.type !== 'player' && ' Founded: ' + item.founded}
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        </TouchableHighlight>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  item: (theme) => ({ backgroundColor: theme.colors.primary })
})

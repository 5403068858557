import React from 'react'
import { StyleSheet, View, Image, ActivityIndicator } from 'react-native'
import { Text, Card } from '@rneui/themed'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { CustomCard } from '../../components/custom/CustomCard'
import { BigCardImage } from './BigCardImage'

export const BigCard = (props) => {
  return (
    <CustomCard>
      <Card.Title style={styles.cardTitle}>
        <Ionicons name="bonfire" color="red" size={16} /> {props.title}
      </Card.Title>
      <Card.Divider color="gray" />
      <View style={styles.container}>
        <BigCardImage source={props.image} />
        <Text style={styles.text}>
          <Ionicons name="triangle" color="green" size={16} /> {props.text}
        </Text>
      </View>
      <Text style={styles.author}>
        <Ionicons name="shapes" color="green" size={16} /> {props.author} -{' '}
        {props.minutes}
      </Text>
    </CustomCard>
  )
}

const styles = StyleSheet.create({
  cardTitle: {
    alignSelf: 'flex-start',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  image: {
    height: 250,
    width: '100%',
    resizeMode: 'cover',
    flexGrow: 1,
    borderRadius: 5,
    alignSelf: 'stretch',
  },
  text: {
    margin: 10,
    marginTop: 15,
    flexBasis: 250,
    justifyContent: 'center',
    textAlign: 'justify',
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  author: { marginTop: 10, fontSize: 14, alignSelf: 'center' },
  viewIndicator: {
    width: '100%',
    height: 250,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

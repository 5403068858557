import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from '@rneui/themed'
import { useTheme } from '@rneui/themed'

export const CustomText = ({ children }) => {
  const { theme } = useTheme()
  return (
    <Text style={styles.text(theme)}> {children} </Text>)
}
 
const styles = StyleSheet.create({
  text: (theme) => ({
    color: theme.colors.title,
    textAlign: 'center'
  })
})
import { logica } from './logica'
import { Card as Tarjeta } from './Card'
import {  
  FAVORITES_KEY_JSON as KEY
} from '../../../../hooks/favorites/useFavorites'

export const PhotoPlayerCard = ({ id, name, photo }) => {
  
  const {
    favorites,
    handleFavorite
  } = logica(id, KEY)

  return (
    <Tarjeta
      id={id}
      name={name}
      photo={photo}
      favorites={favorites}
      handleFavorite={handleFavorite}
    />
  )
}

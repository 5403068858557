import React, { useState, useEffect } from 'react'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { Form } from './Form'

export const DataPlayerCardEditor = ({
  item,
  itemOriginal,
  handleNavigateToProfile
}) => {
  const [player, setPlayer] = useState(null)
  const { scoutingCounter } = useMessageStore()
  const { userLogged } = useMessageStore()

  useEffect(() => {
    setPlayer(item)
  }, [item])

  return (
    <>
      {player && userLogged && userLogged[1] && (
        <Form
          username={userLogged[1]}
          scoutingCounter={scoutingCounter}
          player={player}
          itemOriginal={itemOriginal}
          handleNavigateToProfile={handleNavigateToProfile}
        />
      )}
    </>
  )
}

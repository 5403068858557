import React, { useState, useEffect } from 'react'
import { addItem, getItem, removeItem, updateItem } from './storage'
import { useMessageStore } from '../redux/hooks/useMessage'

export const useStorage = (username, scoutingCounter) => {
  const [players, setPlayers] = useState(null)
  const { setScoutingCounter } = useMessageStore();

  const handleDeletePlayer = async (id) => {
    const response = await removeItem(id, username + '@storage_team')
    setPlayers(response)
    setScoutingCounter(scoutingCounter + 1)
  }

  const handleAddPlayer = async (id) => {
    const response = await addItem(username + '@storage_team')
    setPlayers(response.allPlayers)
    setScoutingCounter(scoutingCounter + 1)
    return response.newPlayer
  }

  const handleUpdatePlayer = async (id, newData) => {
    const response = await updateItem(id, username + '@storage_team', newData)
    setPlayers(response)
    setScoutingCounter(scoutingCounter + 1)
  }

  useEffect(() => {
    const func = async () => {
      if (username) {
        const response = await getItem(username + '@storage_team')
        setPlayers(response)
      }
    }
    func()
  }, [scoutingCounter])

  //console.log("useStorage")
  //console.log(players)

  return {
    players,
    addItem,
    removeItem,
    handleAddPlayer,
    handleDeletePlayer,
    handleUpdatePlayer
  }
}

import React from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Text, Card } from '@rneui/themed'
import { LinearGradient } from 'expo-linear-gradient'
import { CustomCard } from '../../components/custom/CustomCard'
import { configHome as config } from '../../config/home.config'
import { useMessageStore } from '../../redux/hooks/useMessage'
import {
  useFavorites,
  FAVORITES_KEY_API,
} from '../../hooks/favorites/useFavorites'
import { HorizontalFavItemApi } from './HorizontalFavItemApi'

export const HorizontalCardFavoritesApi = ({ handleNavigate }) => {
  const { favoritesCounter } = useMessageStore()
  const { isLoading, favorites: favoritesIDS } = useFavorites(
    favoritesCounter,
    FAVORITES_KEY_API,
  )

  return (
    <ScrollView
      style={styles.scrollView}
      contentContainerStyle={styles.containerScrollView}
      horizontal
      scrollEnabled
    >
      {!isLoading &&
        favoritesIDS &&
        favoritesIDS?.length > 0 &&
        favoritesIDS.map((id) => (
          <HorizontalFavItemApi
            key={id}
            id={id}
            handleNavigate={handleNavigate}
          />
        ))}
      {!isLoading && favoritesIDS.length === 0 && (
        <View
          style={{
            padding: 20,
            width: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ textAlign: 'center' }}>
            No estas siguiendo a ningun jugador
          </Text>
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    marginBottom: 5,
  },
  containerScrollView: {
    textAlign: 'center',
    minWidth: '100%',
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

import React, {useEffect} from 'react'
import { createTheme, ThemeProvider } from '@rneui/themed'
import { Platform } from 'react-native'
import { Provider } from 'react-redux'
import { store } from './src/redux/store/store'
import { TabRoutes } from './src/routes/TabRoutes'
// import { TabRoutes } from './src/routes/TabRoutes'

const theme = createTheme({
  lightColors: {
    primary: '#6A6A6A',
    secondary: '#FFFFFF',
    success: '#4CD964',
    error: '#FF3B30',
    warning: '#FF9500',
    info: '#34AADC',
    background: '#F0F0F0',
    text: '#FFFFFF',
    disabled: '#C7C7CC',
    placeholder: '#C7C7CC',
    tint: '#007AFF',
    tabbar: '#F8F8F8',
    border: '#C7C7CC',
    card: '#F8F8F8',
    action: '#007AFF',
    title: '#000000',
  },
  darkColors: {
    primary: '#1A1A1A',
    secondary: '#1A1A1A',
    success: '#34C759',
    error: '#FF453A',
    warning: '#FF9F0A',
    info: '#5AC8FA',
    background: '#0C0C0C',
    text: '#FFFFFF',
    disabled: '#C7C7CC',
    placeholder: '#8E8E93',
    tint: '#0A84FF',
    tabbar: '#1C1C1E',
    border: '#3E3E40',
    card: '#2C2C2E',
    action: '#0A84FF',
    title: '#FFFFFF',
  },
  mode: 'light'
})

export default function App() { 

  useEffect(() => {
    if (Platform.OS === 'web' && typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
    }
    // Esto desactiva el scroll en el body de la página solo si la plataforma es web
    // y la propiedad window está definida
    // cuando el componente App se monta en la página.
    // También puedes poner esto en otra parte de tu código
    // si deseas activarlo o desactivarlo en otro momento.
    return () => {
      if (Platform.OS === 'web' && typeof window !== 'undefined') {
        document.body.style.overflow = 'auto';
      }
      // Esto reactiva el scroll en el body de la página solo si la plataforma es web
      // y la propiedad window está definida
      // cuando el componente App se desmonta de la página.
    };
  }, []);


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <TabRoutes />
      </ThemeProvider>
    </Provider>
  )
}

import React, { useState, useEffect } from 'react'
import playersFromJSON from '../../_json/data/combinedPlayerData'
import { calculeScore } from '../../helpers/calculeScore'

export const useExplorePlayerByScore = () => {
  const [isLoading, SetIsLoading] = useState(true)
  const [playerList, setPlayerList] = useState([])
  const [playerListFiltered, setPlayerListFiltered] = useState([])
  const [playerListSorted, setPlayerListSorted] = useState([])
  const [arreglo, setArreglo] = useState([])

  useEffect(() => {
    setPlayerList(playersFromJSON)
    SetIsLoading(false)
  }, [])

  useEffect(() => {
    setPlayerListFiltered(
      playerList?.map((item) => {
        return { id: item.player.id, score: calculeScore(item) }
      }),
    )
  }, [playerList])

  useEffect(() => {
    var p = [].concat(playerListFiltered)
    var s = p.sort((a, b) => (a.score > b.score ? -1 : 1))
    setPlayerListSorted(s)
  }, [playerListFiltered])

  useEffect(() => {
    setArreglo([
      playerListSorted[0]?.id,
      playerListSorted[1]?.id,
      playerListSorted[2]?.id,
      playerListSorted[3]?.id,
      playerListSorted[4]?.id,
      playerListSorted[5]?.id,
    ])
  }, [playerListSorted])

  return {
    isLoading,
    playerListFiltered: playerListFiltered,
    playerListSorted: playerListSorted?.slice(0, 4),
    playerListSortedIDS: arreglo,
  }
}

import React, { useState } from 'react'
import { StyleSheet, Platform, View, Text } from 'react-native'
import { Switch } from '@rneui/themed'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { useTheme } from '@rneui/themed'

export const SwitchApi = () => {
  const { usarApi, setUsarApi } = useMessageStore()
  const { theme } = useTheme()

  return (
    <View style={styles.view(theme)}>
      <Switch
        color="yellow"
        thumbColor={theme.colors.title}
        trackColor={{ false: '#767577', true: '#81b0ff' }}
        value={usarApi}
        onValueChange={() => setUsarApi(!usarApi)}
      />
      <Text style={styles.text(theme)}>usar API</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  view: (theme) => ({
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 0,
    height: 50,
    marginBottom: 10,
    position: 'relative',
    // ...Platform.select({
    //   ios: {
    //     height: '50%'
    //   },
    //   android: {
    //     height: '50%'
    //   },
    //   default: {
    //     height: '100%',
    //     marginRight: 10
    //   }
    // })
  }),
  text: (theme) => ({
    position: 'absolute',
    width: '100%',
    color: theme.colors.title,
    textAlign: 'center',
    ...Platform.select({
      ios: {},
      android: {
        bottom: -5,
      },
      default: {
        bottom: -5,
      },
    }),
  }),
})

import { CustomCard } from '../../../../components/custom/CustomCard'
import { Card } from '@rneui/themed'
import { CustomText } from './CustomText'

export const PersonalDataCard = ({ item: item }) => {
  return (
    <CustomCard>
      <Card.Title>Datos Personales</Card.Title>
      <CustomText>Nombres: {item?.firstname}</CustomText>
      <CustomText>Apellido: {item?.lastname}</CustomText>
      <CustomText>
        Altura: {item?.height ? item.height : 'desconocido'} cm{' '}
      </CustomText>
      <CustomText>
        Peso: {item?.weight ? item.weight + ' kg' : 'desconocido'}{' '}
      </CustomText>
      <CustomText>
        Fecha de Nacimiento:{' '}
        {item?.data_of_birth ? item.data_of_birth : 'desconocida'}{' '}
      </CustomText>
      <CustomText>
        Genero: {item?.gender == 'male' ? 'masculino' : 'femenino'}{' '}
      </CustomText>
    </CustomCard>
  )
}

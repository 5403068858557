
export const ligas = [
    {
      id: 2,
      sport_id: 1,
      country_id: 41,
      name: 'Champions League',
      active: true,
      short_code: 'UEFA CL',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/2.png',
      type: 'league',
      sub_type: 'cup_international',
      last_played_at: '2023-04-19 19:00:00',
      category: 1,
      has_jerseys: false
    },
    {
      id: 8,
      sport_id: 1,
      country_id: 462,
      name: 'Premier League',
      active: true,
      short_code: 'UK PL',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/8/8.png',
      type: 'league',
      sub_type: 'domestic',
      last_played_at: '2023-05-01 19:00:00',
      category: 1,
      has_jerseys: false
    },
    {
      id: 82,
      sport_id: 1,
      country_id: 11,
      name: 'Bundesliga',
      active: true,
      short_code: 'GER BI',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/82.png',
      type: 'league',
      sub_type: 'domestic',
      last_played_at: '2023-04-30 15:30:00',
      category: 1,
      has_jerseys: false
    },
    {
      id: 301,
      sport_id: 1,
      country_id: 17,
      name: 'Ligue 1',
      active: true,
      short_code: 'FRA L1',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/301.png',
      type: 'league',
      sub_type: 'domestic',
      last_played_at: '2023-04-30 18:45:00',
      category: 1,
      has_jerseys: false
    },
    {
      id: 384,
      sport_id: 1,
      country_id: 251,
      name: 'Serie A',
      active: true,
      short_code: 'ITA SA',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/0/384.png',
      type: 'league',
      sub_type: 'domestic',
      last_played_at: '2023-04-30 18:45:00',
      category: 1,
      has_jerseys: false
    },
    {
      id: 564,
      sport_id: 1,
      country_id: 32,
      name: 'La Liga',
      active: true,
      short_code: 'ESP PL',
      image_path: 'https://cdn.sportmonks.com/images/soccer/leagues/564.png',
      type: 'league',
      sub_type: 'domestic',
      last_played_at: '2023-05-01 19:00:00',
      category: 1,
      has_jerseys: false
    }
  ]
  
  export const ligasObj = {
    2: 'Champions League',
    8: 'Premier League',
    82: 'Bundesliga',
    301: 'Ligue 1',
    364: 'Serie A',
    564: 'La Liga'
  }
  
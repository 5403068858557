import React, { useState, useEffect } from 'react'

import playersFromJSON from '../../_json/data/combinedPlayerData'
import { calculeScore } from '../../helpers/calculeScore'

// Get a List of data players from an arrayId //

export const useList = (arrayId) => {
  const [isLoading, SetIsLoading] = useState(true)
  const [playerList, setPlayerList] = useState([])

  useEffect(() => {
    setPlayerList(playersFromJSON)
    SetIsLoading(false)
  }, [])

  const list = []
  arrayId &&
    arrayId.forEach((id) => {
      const firstElementFound =
        !isLoading && playerList?.find((item) => id == item.player.id)

      const player = firstElementFound && {
        id: id,
        title: firstElementFound?.player.firstname,
        title2: firstElementFound?.player.lastname,
        image: firstElementFound?.player?.photo,
        score: calculeScore(firstElementFound),
      }
      list.push(player)
    })

  return {
    isLoading,
    list,
  }
}

import playersFromJSON from './data/combinedPlayerData'
import { compareStrings } from '../helpers/stringHelper'

export const getPlayers = () => {
  const data = playersFromJSON
  return data
}

export const getPlayerListByTeamId = (teamId) => {
  const list = getPlayers().filter(
    (item) => item.statistics[0].team.id == teamId,
  )
  return list
}

export const getPlayersBySearchTerm = (players, searchTerm) => {
  return players?.filter(
    (item) =>
      searchTerm?.length > 1 &&
      (compareStrings(searchTerm, item.statistics[0].team.name) ||
        compareStrings(searchTerm, item.player.name)),
    [searchTerm],
  )
}

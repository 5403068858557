import React, { useState, useEffect } from 'react'
import { Card } from '@rneui/themed'
import { View } from 'react-native'
import { CustomCard } from '../../components/custom/CustomCard'
import { CustomText } from '../Explore/Player/DataPlayerCard/CustomText'
import { Input } from '@rneui/themed'

export const DataPlayerCard = ({ item }) => {
  const [player, setPlayer] = useState({})

  useEffect(() => {
    setPlayer(item)
  }, [item])

  const handleChange = (key, value) => {
    setPlayer((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <>
      {player && (
        <CustomCard>
          <CustomText>Nombre Completo: {player.name}</CustomText>
          <CustomText>Nombres: {player.firstname}</CustomText>
          <CustomText>Apellidos: {player.lastname}</CustomText>
          <CustomText>Edad: {player.age} años</CustomText>
          <CustomText>Género: {player.genre}</CustomText>
          <CustomText>Nacionalidad: {player.nationality}</CustomText>
          <CustomText>Fecha de Nacimiento: {player.birthDate}</CustomText>
          <CustomText>Lugar de Nacimiento: {player.birthPlace}</CustomText>
          <CustomText>País de Nacimiento {player.birthCountry}</CustomText>
          <CustomText>Peso: {player.weight} kg</CustomText>
          <CustomText>Altura: {player.height} cm</CustomText>
          <CustomText>Lesionado: {player.injured}</CustomText>
          <CustomText>Club: {player.club}</CustomText>
          <CustomText>Posicion: {player.games?.position}</CustomText>
          <CustomText>Camiseta: {player.games?.number}</CustomText>
          <CustomText>Comentarios: {player.comments}</CustomText>
        </CustomCard>
      )}
    </>
  )
}

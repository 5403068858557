import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Card } from '@rneui/themed'
import { CustomCard } from '../custom/CustomCard'
import { useIsImageLoaded } from '../../hooks/images/useIsImageLoaded'
import { FadeInView } from '../effect/FadeInView'

export const LogoClubCard = ({ name, logo }) => {
  const { isImageLoaded, isTimeOut, handleImageLoaded, handleImageError } =
    useIsImageLoaded()
  return (
    <>
      {isImageLoaded && (
        <CustomCard>
          <Card.Title>{name}</Card.Title>
          <FadeInView style={{ flex: 1 }}>
            <View style={styles.imageContainer}>
              {logo && (
                <Card.Image style={styles.image} source={{ uri: logo }} />
              )}
            </View>
          </FadeInView>
        </CustomCard>
      )}
      <>
        {!isImageLoaded && (
          <CustomCard>
            <Card.Title style={styles.title}>Estadio</Card.Title>
            <ActivityIndicator color="rgba(251,133,0,1)" size={100} />
            <Card.Image
              style={{ width: 1, height: 1, marginTop: 20 }}
              source={{ uri: logo }}
              onLoadEnd={handleImageLoaded}
              onError={handleImageError}
            />
          </CustomCard>
        )}
      </>
    </>
  )
}

const styles = StyleSheet.create({
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: { width: 100, height: 100, margin: 10 },
})

export const calculeScore = (player) => {
    return (
  parseInteger(player?.statistics[0]?.games?.appearences) +
  parseInteger(player?.statistics[0]?.games?.lineups) +
  parseInteger(player?.statistics[0]?.games?.minutes) +
  parseInteger(player?.statistics[0]?.games?.number) +
  parseInteger(player?.statistics[0]?.games?.rating) +
  parseInteger(player?.statistics[0]?.games?.captain) +
  parseInteger(player?.statistics[0]?.substitutes?.in) +
  parseInteger(player?.statistics[0]?.substitutes?.out) +
  parseInteger(player?.statistics[0]?.substitutes?.bench) +
  parseInteger(player?.statistics[0]?.shots?.total) +
  parseInteger(player?.statistics[0]?.shots?.on) +
  parseInteger(player?.statistics[0]?.goals?.total) +
  parseInteger(player?.statistics[0]?.goals?.conceded) +
  parseInteger(player?.statistics[0]?.goals?.assists) +
  parseInteger(player?.statistics[0]?.goals?.saves) +
  parseInteger(player?.statistics[0]?.passes?.total) +
  parseInteger(player?.statistics[0]?.passes?.key) +
  parseInteger(player?.statistics[0]?.passes?.accuracy) +
  parseInteger(player?.statistics[0]?.tackles?.total) +
  parseInteger(player?.statistics[0]?.tackles?.blocks) +
  parseInteger(player?.statistics[0]?.tackles?.interceptions) +
  parseInteger(player?.statistics[0]?.duels?.total) +
  parseInteger(player?.statistics[0]?.duels?.total) +
  parseInteger(player?.statistics[0]?.dribbles?.attempts) +
  parseInteger(player?.statistics[0]?.dribbles?.success) +
  parseInteger(player?.statistics[0]?.dribbles?.past) +
  parseInteger(player?.statistics[0]?.fouls?.drawn) +
  parseInteger(player?.statistics[0]?.fouls?.commited) +
  parseInteger(player?.statistics[0]?.cards?.yellow) +
  parseInteger(player?.statistics[0]?.cards?.yellowred) +
  parseInteger(player?.statistics[0]?.cards?.red) +
  parseInteger(player?.statistics[0]?.penalty?.won) +
  parseInteger(player?.statistics[0]?.penalty?.commited) +
  parseInteger(player?.statistics[0]?.penalty?.scored) +
  parseInteger(player?.statistics[0]?.penalty?.missed) +
  parseInteger(player?.statistics[0]?.penalty?.saved))
}

const parseInteger = (value) => {
    const result = parseInt(value)
    if (isNaN(result)) return 0
    return result
}
import React from 'react'
import { Text, Card } from '@rneui/themed'
import { View, StyleSheet, Image } from 'react-native'
import { CustomCard } from '../../components/custom/CustomCard'
import { useGetNews } from '../../hooks/api/useGetNews'
import Ionicons from 'react-native-vector-icons/Ionicons'

export const CardListApi = (props) => {
  const { isLoading, news } = useGetNews()

  const convertirFecha = (fecha) => {
    const fechaOriginal = new Date(fecha)
    const dia = fechaOriginal.getDate().toString().padStart(2, '0')
    const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0')
    const anio = fechaOriginal.getFullYear().toString().substring(2)
    // Creamos la fecha en el formato deseado
    const fechaConvertida = `${dia}-${mes}-${anio}`
    return fechaConvertida
  }

  return (
    <CustomCard>
      <Card.Title>{props.list.title}</Card.Title>

      <View style={styles.container}>
        {!isLoading && (
          <View style={{ flexWrap: 'wrap' }}>
            {news?.data?.map((item) => (
              <View key={item.id}>
                <CustomCard>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                  >
                    <Image
                      style={styles.image}
                      resizeMode="cover"
                      source={{ uri: item.league.image_path }}
                    />
                    <Text style={{ fontSize: 12 }}>
                      {' '}
                      Liga: {item.league.name}
                    </Text>
                  </View>
                  <Text style={{ marginTop: 8 }}>
                    <Ionicons name="bonfire" color="red" size={16} />
                    {' ' + item.title}
                  </Text>
                  <Text style={{ fontSize: 10, marginTop: 8 }}>
                    <Ionicons name="shapes" color="green" size={16} /> Sport
                    Monks - {convertirFecha(item.league.last_played_at)}
                  </Text>
                </CustomCard>
              </View>
            ))}
            {news?.data === undefined && (
              <Text> No se encontraron noticias</Text>
            )}
          </View>
        )}
      </View>
    </CustomCard>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item1: {},
  item2: {
    width: '80%',
  },
  fonts: {
    marginBottom: 8,
  },
  image: {
    width: 16,
    height: 16,
  },
  text: {
    fontSize: 16,
    marginTop: 0,
  },
})

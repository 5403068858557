import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Text, Card } from '@rneui/themed'
import { LinearGradient } from 'expo-linear-gradient'
import { CustomCard } from '../../components/custom/CustomCard'
import { useGetPlayer } from '../../hooks/api/useGetPlayer'

export const HorizontalFavItemApi = ({ id, handleNavigate }) => {
  const { isLoading, player } = useGetPlayer(id)

  return (
    <TouchableOpacity onPress={() => handleNavigate(id)}>
      <CustomCard>
        <Card.Image
          containerStyle={styles.containerImage}
          style={styles.image}
          source={{ uri: player?.data?.image_path }}
        ></Card.Image>
        <LinearGradient
          // Background Linear Gradient
          colors={[
            'transparent',
            'transparent',
            'rgba(0,0,0,0.25)',
            'rgba(0,0,0,1)',
          ]}
          style={styles.gradient}
        />
        <Text style={styles.text2}>{player?.data?.common_name}</Text>
        <Text style={styles.text1}>
          Score: {id / 4000000 < 1 ? 8.5 : (id / 4000000).toFixed(1)}
        </Text>
      </CustomCard>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  containerImage: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  text1: {
    color: 'white',
    position: 'absolute',
    top: 120,
    fontSize: 10,
    width: 150,
    textAlign: 'center',
    fontWeight: '500',
  },
  text2: {
    color: 'white',
    position: 'absolute',
    top: 130,
    fontSize: 10,
    width: 150,
    textAlign: 'center',
    fontWeight: '500',
  },
  text3: {
    fontSize: 12,
    width: 150,
    textAlign: 'center',
  },
  image: {
    borderRadius: 5,
    width: 150,
    height: 150,
    overflow: 'hidden',
  },
  gradient: {
    width: 150,
    height: 60,
    overflow: 'hidden',
    position: 'absolute',
    top: 90,
    left: 0,
    boxShadow: 'inset 0px 0px 0px rgba(0,0,0,0.25)',
  },
})

import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { CustomCard } from '../../../../components/custom/CustomCard'
import { useGetType } from '../../../../hooks/api/useGetType'
import { Card } from '@rneui/themed'
import { CustomText } from './CustomText'

export const DataPlayerType = ({ typeObj }) => {
  const { isLoading, type } = useGetType(typeObj.type_id)

  return (
    <CustomCard>
      <Card.Title>
        {' '}
        {type?.data?.name ? type.data.name : 'Cargando...'}{' '}
      </Card.Title>
      <View>
        {Object.entries(typeObj.value).map(([key, value]) => (
          <View key={key}>
            <CustomText>
              {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
            </CustomText>
          </View>
        ))}
      </View>
    </CustomCard>
  )
}

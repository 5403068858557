import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import Svg, { Path, Circle } from 'react-native-svg'

export const SearchSVG = (props) => {
  return (
    <View style={styles.view}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={649.3779 / 3}
        height={727.77798 / 3}
        viewBox="0 0 649.3779 727.77798"
        {...props}
      >
        <Path
          d="M362.89 172.947h-4V63.402A63.402 63.402 0 00295.49 0H63.402A63.402 63.402 0 000 63.402v600.974a63.402 63.402 0 0063.402 63.402h232.086a63.402 63.402 0 0063.402-63.402V250.923h4z"
          fill="#e6e6e6"
        />
        <Path
          d="M298.047 16.495h-30.295a22.495 22.495 0 01-20.827 30.99h-132.96a22.495 22.495 0 01-20.827-30.99H64.842a47.348 47.348 0 00-47.347 47.348v600.092a47.348 47.348 0 0047.347 47.348h233.205a47.348 47.348 0 0047.348-47.348V63.843a47.348 47.348 0 00-47.348-47.348z"
          fill="#fff"
        />
        <Path
          data-name="a2804879-ded6-4045-b20f-1f1dde9b938b"
          d="M611.462 573.93H331.843a5.184 5.184 0 01-5.178-5.178V499.39a5.184 5.184 0 015.178-5.178h279.62a5.184 5.184 0 015.177 5.178v69.362a5.184 5.184 0 01-5.178 5.177zm-279.619-77.646a3.11 3.11 0 00-3.107 3.107v69.362a3.11 3.11 0 003.107 3.107h279.62a3.11 3.11 0 003.106-3.107V499.39a3.11 3.11 0 00-3.107-3.107z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Circle
          data-name="b9ad11c9-d8a0-4df6-8741-900b9ec46a35"
          cx={82.77841}
          cy={447.95985}
          r={21.74799}
          fill="#e6e6e6"
        />
        <Path
          data-name="bd261eec-7ae0-49b0-bf26-57ff03972605"
          d="M418.317 519.573a3.625 3.625 0 000 7.249h170.878a3.625 3.625 0 00.14-7.249H589.195z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Path
          data-name="e80b4447-8c34-435b-ba6c-5300a190ab24"
          d="M418.317 541.32a3.625 3.625 0 000 7.25h170.878a3.625 3.625 0 00.12-7.25h-.12z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Path
          data-name="e55fcb7d-3a3b-45d8-b167-72fb2263dd92"
          d="M611.462 689.92H331.843a5.184 5.184 0 01-5.178-5.178V615.38a5.184 5.184 0 015.178-5.178h279.62a5.184 5.184 0 015.177 5.178v69.362a5.184 5.184 0 01-5.178 5.177zm-279.619-77.646a3.11 3.11 0 00-3.107 3.107v69.362a3.11 3.11 0 003.107 3.107h279.62a3.11 3.11 0 003.106-3.107V615.38a3.11 3.11 0 00-3.107-3.107z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Circle
          data-name="a50d232f-7710-43e4-8fa9-6ef0443fc454"
          cx={82.77841}
          cy={563.94987}
          r={21.74799}
          fill="#e6e6e6"
        />
        <Path
          data-name="e49b4965-a9e9-4371-9134-194e44e65c31"
          d="M418.317 635.563a3.625 3.625 0 000 7.249h170.878a3.625 3.625 0 00.12-7.25h-.12z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Path
          data-name="abc4586a-ac92-4255-aae6-84f53baad571"
          d="M418.317 657.31a3.625 3.625 0 000 7.25h170.878a3.625 3.625 0 00.12-7.25h-.12z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Path
          data-name="a9e593af-a319-4e97-9065-f0c2c04624d5"
          d="M465.768 429.995a98.343 98.343 0 01-98.384-98.302v-.082c0-.206 0-.423.012-.63.3-53.878 44.432-97.755 98.372-97.755a98.384 98.384 0 01.023 196.768h-.023zm0-194.7a96.519 96.519 0 00-96.3 95.749c-.01.22-.01.4-.01.564a96.325 96.325 0 1096.336-96.313h-.026z"
          transform="translate(-285.311 -86.111)"
          fill="#e6e6e6"
        />
        <Circle cx={315.11393} cy={422.84174} r={40} fill="#fff" />
        <Path
          d="M301.222 440.838c-.069 0-.138 0-.206-.002h-21.5a10.398 10.398 0 01-.009-20.793h21.518c.106-.002.22-.002.334 0a10.398 10.398 0 01-.137 20.795z"
          fill="#6c63ff"
        />
        <Path
          fill="#3f3d56"
          d="M325.3453 725.34374H649.3779099999999V727.58446H325.3453z"
        />
        <Path
          d="M345.31 535.731a6.561 6.561 0 01-.63-1.259l-5.163-13.827a6.508 6.508 0 013.815-8.363l115.242-43.032a6.506 6.506 0 018.363 3.815l5.163 13.828a6.5 6.5 0 01-3.816 8.363l-115.24 43.031a6.507 6.507 0 01-7.734-2.556z"
          fill="#6c63ff"
        />
        <Path
          d="M458.207 465.677l-46.863 18.687a6 6 0 00-4.093 7.2l5.439 21.775a6 6 0 008.376 3.975l46.992-22.119a6.01 6.01 0 003.525-7.717l-5.649-18.275a6.01 6.01 0 00-7.727-3.526z"
          fill="#2f2e41"
        />
        <Path
          d="M543.97 601.339a6.507 6.507 0 01-6.893-4.34l-40.114-116.29a6.5 6.5 0 014.025-8.264l13.953-4.813a6.504 6.504 0 018.264 4.025l40.114 116.29a6.508 6.508 0 01-4.025 8.264l-13.953 4.813a6.561 6.561 0 01-1.372.315z"
          fill="#6c63ff"
        />
        <Path
          d="M526.195 469.667l15.396 48.045a6 6 0 01-2.951 7.739l-20.26 9.657a6 6 0 01-8.396-3.934l-12.83-50.328a6.01 6.01 0 013.711-7.63l17.694-7.268a6.01 6.01 0 017.636 3.72zM479.58 726.003h-14.76a6.508 6.508 0 01-6.5-6.5V596.488a6.508 6.508 0 016.5-6.5h14.76a6.508 6.508 0 016.5 6.5v123.015a6.508 6.508 0 01-6.5 6.5zM508.772 726.003h-14.76a6.508 6.508 0 01-6.5-6.5V596.488a6.508 6.508 0 016.5-6.5h14.76a6.508 6.508 0 016.5 6.5v123.015a6.508 6.508 0 01-6.5 6.5z"
          fill="#2f2e41"
        />
        <Path
          d="M520.936 610.95h-67.063a7.008 7.008 0 01-7-7V495.007a40.531 40.531 0 0181.063 0v108.945a7.008 7.008 0 01-7 7z"
          fill="#ccc"
        />
        <Path
          d="M531.03 637.6h-34V445.445l.632.173a45.382 45.382 0 0133.368 43.687zM475.015 637.6h-34V489.305a45.382 45.382 0 0133.369-43.687l.631-.173z"
          fill="#2f2e41"
        />
        <Circle cx={486.89704} cy={378.42061} r={53.51916} fill="#6c63ff" />
        <Path
          d="M547.404 366.099s3-83-36-56c0 0-22.5-75.5-77.5 37.5l-16 16s72-10 139 21z"
          fill="#2f2e41"
        />
        <Path
          d="M487.713 404.925c-3.306-.091-7.42-.206-10.59-2.522a8.133 8.133 0 01-3.2-6.072 5.47 5.47 0 011.86-4.494c1.656-1.398 4.074-1.727 6.679-.961l-2.7-19.726 1.982-.271 3.173 23.19-1.654-.76c-1.919-.88-4.552-1.327-6.188.056a3.515 3.515 0 00-1.153 2.895 6.147 6.147 0 002.381 4.528c2.467 1.802 5.746 2.034 9.466 2.138z"
          fill="#2f2e41"
        />
        <Path
          fill="#2f2e41"
          d="M459.19183 372.85162H469.96344V374.85162H459.19183z"
        />
        <Path
          fill="#2f2e41"
          d="M493.19183 372.85162H503.96344V374.85162H493.19183z"
        />
        <Path
          d="M367.571 540.833a6 6 0 01-7.968-2.918l-34.51-74.384a6 6 0 0110.885-5.05l34.51 74.384a6 6 0 01-2.917 7.968z"
          fill="#3f3d56"
        />
        <Path
          d="M332.114 460.794a4 4 0 01-4-4v-50.952h-50a4 4 0 010-8h54a4 4 0 014 4v54.952a4 4 0 01-4 4z"
          fill="#6c63ff"
        />
        <Path
          d="M333.06 466.449a48 48 0 1123.34-63.744 48.054 48.054 0 01-23.34 63.744zm-35.353-76.198a36 36 0 1047.808 17.505 36.04 36.04 0 00-47.808-17.505z"
          fill="#3f3d56"
        />
      </Svg>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: 'white',
    padding: 10,
    marginTop: 15,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: 640,
    alignSelf: 'center'
  }
})

// const images = [
//   require('../assets/img/messi.jpg'),
//   require('../assets/img/batistuta.jpg'),
//   require('../assets/img/riverboca.jpg'),
//   require('../assets/img/riverboca.jpg'),
// ]

export const homeConfig = {
  webWidth: 600,
  webMaxWidth: 700,
  webAlignSelf: 'center',
  title1: 'Siguiendo',
  title2: 'Recomendados',
  title3: 'Ultimas Noticias',
  bigCard: [
    {
      title: {
        english: 'Leo Messi',
        spanish: 'Leo Messi',
      },
      news: {
        text: {
          english:
            'Leo Messi volvera al Barcelona el proximo 1° de Julio según Vero Brunatti.',
          spanish:
            'Leo Messi volvera al Barcelona el proximo 1° de Julio según Vero Brunatti.',
        },
        author: 'Infootball',
        time: {
          english: 'Hace 1 minuto',
          spanish: 'Hace 1 minuto',
        },
        image: 'https://mercado.webapp.ar/images_soccer/messi.jpg',
      },
    },
    {
      title: {
        english: 'Gabriel Omar Batistuta',
        spanish: 'Gabriel Omar Batistuta',
      },
      news: {
        text: {
          english:
            'Las mejores jugadas de Gabriel Batistuta durante su gran carrera futbolistica',
          spanish:
            'Las mejores jugadas de Gabriel Batistuta durante su gran carrera futbolistica.',
        },
        author: 'Infootball',
        time: {
          english: 'Hace 2 minutos',
          spanish: 'Hace 2 minutos',
        },
        image: 'https://mercado.webapp.ar/images_soccer/batistuta.jpg',
      },
    },
    {
      title: {
        english: 'River Plate vs Boca Juniors',
        spanish: 'River Plate vs Boca Juniors',
      },
      news: {
        text: {
          english: 'Uno de los clasicos sudamericanos mas importantes.',
          spanish: 'Uno de los clasicos sudamericanos mas importantes',
        },
        author: 'Infootball',
        time: {
          english: 'Hace 3 minutos',
          spanish: 'Hace 3 minutos',
        },
        image: 'https://mercado.webapp.ar/images_soccer/riverboca.jpg',
      },
    },
  ],
  list: {
    title: 'Resumen del dia',
    news: [
      {
        text: {
          english: 'River vs Boca. Hoy 16:00 hs por ESPN',
          spanish: 'River vs Boca. Hoy 16:00 hs por ESPN',
        },
        image: 'https://mercado.webapp.ar/images_soccer/messi.jpg',
      },
      {
        text: {
          spanish: 'Gabriel Omar Batistuta: el idolo de Boca Juniors',
          english: 'Gabriel Omar Batistuta: el idolo de Boca Juniors',
        },
        image: 'https://mercado.webapp.ar/images_soccer/batistuta.jpg',
      },
      {
        text: {
          spanish: 'River Plate: preocupan las lesiones de varios jugadores',
          english: 'River Plate: preocupan las lesiones de varios jugadores',
        },
        image: 'https://mercado.webapp.ar/images_soccer/riverboca.jpg',
      },
    ],
  },
}

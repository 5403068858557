import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation, canGoBack } from '@react-navigation/native'
import { Home } from '../screens/Home/Home'
import { HomePlayer } from '../screens/Home/HomePlayer'
import { HomePlayerApi } from '../screens/Home/HomePlayerApi'
import { Explore } from '../screens/Explore/Explore'
import { Club } from '../screens/Explore/Club/Club'
import { ClubApi } from '../screens/Explore/Club/ClubApi'
import { Player } from '../screens/Explore/Player/Player'
import { PlayerApi } from '../screens/Explore/Player/PlayerApi'
import { MyPlayer } from '../screens/MyClub/MyPlayer'
import { MyClub } from '../screens/MyClub/MyClub'
import { Login } from '../screens/Auth/Login'
import { Register } from '../screens/Auth/Register'
import { Profile } from '../screens/Auth/Profile'
import { ThemeButton } from '../components/button/ThemeButton'
import { SwitchApi } from '../components/button/SwitchApi'
import { useMessageStore } from '../redux/hooks/useMessage'
import { View, Image, TouchableOpacity, Platform } from 'react-native'
import { Text } from '@rneui/themed'
import { useWindowDimensions } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { MyProfilePlayer } from '../screens/Auth/MyProfilePlayer'

const isWeb = Platform.OS === 'web'
const showTitle = false
const Stack = createNativeStackNavigator()

const options = {
  headerStyle: {},
  headerTitle: 'Infootball',
  headerRight: () => {
    const window = useWindowDimensions()
    const { usarApi } = useMessageStore()

    return (
      <View
        style={{
          marginRight: isWeb ? window.width / 2 - 590 : 0,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text> {usarApi ? 'Usando API   ' : 'Usando JSON files   '} </Text>
        {/* <SwitchApi/> */}
        <ThemeButton />
      </View>
    )
  },
  headerLeft: () => {
    const navigation = useNavigation()
    const window = useWindowDimensions()
    const canGoBack = navigation.canGoBack()

    const handleGoBack = () => {
      if (canGoBack) navigation.goBack()
    }
    return (
      <TouchableOpacity
        style={{
          marginLeft: isWeb ? window.width / 2 - 590 : 0,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={handleGoBack}
      >
        {!canGoBack && (
          <Image
            style={{ width: 45, height: 45, borderRadius: 5, marginRight: 5 }}
            source={require('../assets/img/favicon-2.png')}
          />
        )}
        {canGoBack && (
          <Ionicons
            style={{ marginRight: 4 }}
            name="arrow-back-outline"
            color="green"
            size={45}
          />
        )}
      </TouchableOpacity>
    )
  },
}

const optionsSinSwitch = {
  ...options,
  headerRight: () => {
    const window = useWindowDimensions()
    return (
      <View
        style={{
          marginRight: isWeb ? window.width / 2 - 390 : 0,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThemeButton />
      </View>
    )
  },
}

export const StackRoutesHome = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen options={options} name="Home" component={Home} />
      <Stack.Screen
        options={optionsSinSwitch}
        name="HomePlayer"
        component={HomePlayer}
      />
      <Stack.Screen
        options={optionsSinSwitch}
        name="HomePlayerApi"
        component={HomePlayerApi}
      />
    </Stack.Navigator>
  )
}

export const StackRoutesExplore = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen options={options} name="Explore" component={Explore} />
      <Stack.Screen
        options={optionsSinSwitch}
        name="Player"
        component={Player}
      />
      <Stack.Screen
        options={optionsSinSwitch}
        name="PlayerApi"
        component={PlayerApi}
      />
      <Stack.Screen options={optionsSinSwitch} name="Club" component={Club} />
      <Stack.Screen
        options={optionsSinSwitch}
        name="ClubApi"
        component={ClubApi}
      />
    </Stack.Navigator>
  )
}

export const StackRoutesMyClub = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={optionsSinSwitch}
        name="MyClub"
        component={MyClub}
      />
      <Stack.Screen
        options={optionsSinSwitch}
        name="MyPlayer"
        component={MyPlayer}
      />
      <Stack.Screen
        options={optionsSinSwitch}
        name="MyPlayerRegister"
        component={MyPlayer}
      />
    </Stack.Navigator>
  )
}

export const StackRoutesAuth = () => {
  const { isLogged } = useMessageStore()
  return (
    <Stack.Navigator>
      {isLogged ? (
        <>
          <Stack.Screen
            options={optionsSinSwitch}
            name="Profile"
            component={Profile}
          />
          <Stack.Screen
            options={optionsSinSwitch}
            name="MyProfilePlayer"
            component={MyProfilePlayer}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            options={optionsSinSwitch}
            name="Login"
            component={Login}
          />
          <Stack.Screen
            options={optionsSinSwitch}
            name="Register"
            component={Register}
          />
        </>
      )}
    </Stack.Navigator>
  )
}

import React from 'react'
import { View } from 'react-native'
import { BigCard } from './BigCard'
import { LittleCard } from './LittleCard'
import { CardList } from './CardList'
import { CardListApi } from './CardListApi'
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { HorizontalCardFavorites } from './HorizontalCardFavorites'
import { HorizontalCardFavoritesApi } from './HorizontalCardFavApi'
import { HorizontalCardFavoritesRandomApi } from './HorizontalCardFavRandomApi'
import { HorizontalCardRecomended } from './HorizontalCardRecomended'
import { SwitchApi } from '../../components/button/SwitchApi'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { homeConfig } from '../../config/home.config'

export const Home = ({ navigation }) => {
  const { usarApi } = useMessageStore()
  const handleNavigate = (item) => {
    navigation.navigate('HomePlayer', { id: item.id })
  }

  const handleNavigatePlayerApi = (id) => {
    navigation.navigate('HomePlayerApi', { id: id })
  }

  return (
    <CustomScrollView>
      {/* <Header title="Configuracion" />
      <SwitchApi /> */}
      <View>
        {!usarApi && (
          <>
            <Header title={homeConfig.title1} />
            <HorizontalCardFavorites handleNavigate={handleNavigate} />
            <Header title={homeConfig.title2} />
            <HorizontalCardRecomended handleNavigate={handleNavigate} />
          </>
        )}
        {!usarApi && (
          <>
            <Header title={homeConfig.title3} />
            {homeConfig.bigCard.map((item, index) => (
              <BigCard
                key={index}
                title={item.title.english}
                text={item.news.text.english}
                author={item.news.author}
                minutes={item.news.time.english}
                image={item.news.image}
              />
            ))}
            {homeConfig.bigCard.map((item, index) => (
              <LittleCard
                key={index}
                title={item.title.english}
                text={item.news.text.english}
                author={item.news.author}
                minutes={item.news.time.english}
                image={item.news.image}
              />
            ))}
            <CardList list={homeConfig.list} />
          </>
        )}

        {usarApi && (
          <>
            <Header title={homeConfig.title1} />
            <HorizontalCardFavoritesApi
              handleNavigate={handleNavigatePlayerApi}
            />
            <Header title={homeConfig.title2} />
            <HorizontalCardFavoritesRandomApi
              handleNavigate={handleNavigatePlayerApi}
            />
            <Header title={homeConfig.title3} />
            <CardListApi list={homeConfig.list} />
          </>
        )}
      </View>
    </CustomScrollView>
  )
}

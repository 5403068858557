import {
  getTeamData,
  getPlayersData,
  getPlayerData,
  searchTeamsData,
  getTypeData,
  getVenueData,
  getSeasonData,
  getCountryData,
  getPreMatchNewsData,
  searchSeasonsData,
  searchPlayersData,
} from './api'

export const searchTeamsService = async (searchTerm) => {
  const response = await searchTeamsData(searchTerm)  
  const data = response ? response.data.data : []
  return { data }
}

export const searchPlayersService = async (searchTerm) => {
  const response = await searchPlayersData(searchTerm)  
  const data = response ? response.data.data : []
  return { data }
}

export const getTeamService = async (teamId) => {  
  const response = await getTeamData(teamId)  
  const data = response ? response.data.data : []
  return { data }
}

export const getPlayersService = async (teamId) => {
  const response = await getPlayersData(teamId)  
  const data = response ? response.data.data : []
  return { data }
}

export const getPlayerService = async (playerId) => {
  const response = await getPlayerData(playerId)  
  const data = response ? response.data.data : []  
  return { data: data }
}

export const getVenueService = async (venueId) => {
  const response = await getVenueData(venueId)  
  const data = response ? response.data.data : []  
  return { data: data }
}

export const getTypeService = async (typeId) => {
  const response = await getTypeData(typeId)  
  const data = response ? response.data.data : []  
  return { data: data }
}

export const searchSeasonsService = async (searchTerm) => {
  const response = await searchSeasonsData(searchTerm)  
  const data = response ? response.data.data : []
  return { data }
}

export const getSeasonService = async (seasonId) => {
  const response = await getSeasonData(seasonId)  
  const data = response ? response.data.data : []  
  return { data: data }
}

export const getCountryService = async (countryId) => {
  const response = await getCountryData(countryId)
  const data = response ? response.data.data : []  
  return { data: data }
}

export const getPreMatchNewsService = async () => {
  const response = await getPreMatchNewsData()  
  const data = response ? response.data.data : []  
  return { data: data }
}

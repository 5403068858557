import React from 'react'
import { StyleSheet, ScrollView, Platform } from 'react-native'
import { useTheme } from '@rneui/themed'

export const CustomScrollView = ({ children }) => {
  const { theme } = useTheme()

  return <ScrollView style={styles.scrollView(theme)}>{children}</ScrollView>
}

const styles = StyleSheet.create({
  scrollView: (theme) => ({
    flex: 1,
    backgroundColor: theme.colors.background,
    ...Platform.select({
      ios: {
        alignSelf: 'auto'
      },
      android: {
        alignSelf: 'auto',        
      },
      default: {
        width: '100%',
        maxWidth: 1200,
        alignSelf: 'center'
      }
    })
  })
})

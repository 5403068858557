import React, { useState } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Text, Card } from '@rneui/themed'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { CustomCard } from '../custom/CustomCard'
import { FadeInView } from '../effect/FadeInView'
import { useIsImageLoaded } from '../../hooks/images/useIsImageLoaded'

export const StadiumClubCard = ({ name, image, capacity, surface, city }) => {
  const { isImageLoaded, handleImageLoaded, handleImageError } =
    useIsImageLoaded()

  return (
    <>
      {isImageLoaded && (
        <CustomCard>
          <Card.Title style={styles.title}>Estadio</Card.Title>
          <FadeInView style={{ flex: 1 }}>
            <View style={styles.imageContainer}>
              {image && (
                <Card.Image style={styles.image} source={{ uri: image }} />
              )}
            </View>
          </FadeInView>
          <View style={styles.view}>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="football-outline"
                color="green"
                size={16}
              />
              {'  '}Estadio: {name}
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="earth-outline"
                color="green"
                size={16}
              />
              {'  '}Ciudad: {city}
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="people-outline"
                color="green"
                size={16}
              />
              {'  '}Capacidad: {capacity} personas
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="planet-outline"
                color="green"
                size={16}
              />
              {'  '}Superficie: {surface}
            </Text>
            <Text style={{ padding: 10 }}></Text>
          </View>
        </CustomCard>
      )}
      <>
        {!isImageLoaded && (
          <CustomCard>
            <Card.Title style={styles.title}>Estadio</Card.Title>
            <ActivityIndicator color="rgba(251,133,0,1)" size={100} />
            <Card.Image
              style={{ width: 1, height: 1, marginTop: 20 }}
              source={{ uri: image }}
              onLoadEnd={handleImageLoaded}
              onError={handleImageError}
            />
          </CustomCard>
        )}
      </>
    </>
  )
}

const styles = StyleSheet.create({
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 200,
    margin: 25,
    marginTop: 10,
    borderRadius: 5,
  },
  text: {
    textAlign: 'center',
    lineHeight: 25,
  },
})

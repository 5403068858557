import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import Svg, { Path, Circle } from 'react-native-svg'

export const LoginSVG = (props) => {
  return (
    <View style={styles.view}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={869.99994 / 3}
        height={520.13854 / 3}
        viewBox="0 0 869.99994 520.13854"
        {...props}
      >
        <Path
          d="M666.092 514.257c-11.138-9.412-17.904-24.28-16.13-38.754s12.764-27.78 27.019-30.854 30.504 5.435 34.834 19.36c2.383-26.847 5.128-54.818 19.402-77.68 12.924-20.701 35.308-35.514 59.568-38.164s49.803 7.359 64.933 26.507 18.835 46.986 8.238 68.97c-7.806 16.194-22.188 28.246-37.257 38.051a240.452 240.452 0 01-164.453 35.977z"
          fill="#f2f2f2"
        />
        <Path
          d="M831.728 356.079a393.414 393.414 0 00-54.826 54.442 394.561 394.561 0 00-61.752 103.194c-1.112 2.725 3.312 3.911 4.412 1.217a392.342 392.342 0 01115.401-155.618c2.285-1.86-.97-5.08-3.235-3.235z"
          fill="#fff"
        />
        <Path
          d="M280.067 511.7c15.299-12.928 24.591-33.349 22.154-53.229s-17.53-38.156-37.11-42.377-41.897 7.464-47.844 26.59c-3.272-36.874-7.044-75.292-26.648-106.693-17.751-28.433-48.497-48.778-81.818-52.418S40.397 293.68 19.616 319.98s-25.87 64.535-11.315 94.73c10.722 22.242 30.476 38.796 51.172 52.263 66.03 42.966 147.94 60.885 225.878 49.415"
          fill="#f2f2f2"
        />
        <Path
          d="M52.567 294.442a540.355 540.355 0 0175.304 74.777 548.076 548.076 0 0159.386 87.89 545.835 545.835 0 0125.43 53.847c1.527 3.742-4.55 5.371-6.06 1.67a536.36 536.36 0 00-49.01-92.727 539.734 539.734 0 00-65.728-81.198 538.44 538.44 0 00-43.766-39.815c-3.138-2.555 1.332-6.978 4.444-4.444z"
          fill="#fff"
        />
        <Path
          d="M624.5 519h-365V144.5C259.5 64.822 324.322 0 404 0h76c79.678 0 144.5 64.822 144.5 144.5z"
          fill="#f2f2f2"
        />
        <Path
          d="M548.5 519h-289V144.5a143.382 143.382 0 0127.596-84.944c.663-.905 1.326-1.798 2.008-2.681a144.466 144.466 0 0130.755-29.851c.66-.48 1.322-.952 1.994-1.423a144.16 144.16 0 0131.472-16.46c.66-.25 1.334-.5 2.007-.742a144.02 144.02 0 0131.108-7.336c.658-.09 1.333-.16 2.008-.23a146.288 146.288 0 0131.106 0c.673.07 1.348.14 2.014.231a143.995 143.995 0 0131.1 7.335c.673.241 1.346.492 2.009.743a143.8 143.8 0 0131.106 16.216c.672.461 1.344.933 2.007 1.405a145.987 145.987 0 0118.383 15.564 144.305 144.305 0 0112.725 14.55c.68.88 1.342 1.774 2.005 2.677A143.382 143.382 0 01548.5 144.5z"
          fill="#ccc"
        />
        <Circle cx={524.99994} cy={335.5} r={16} fill="#6c63ff" />
        <Path
          fill="#ffb8b8"
          d="M594.599 507.783L582.339 507.783 576.506 460.495 594.601 460.496 594.599 507.783z"
        />
        <Path
          d="M573.582 504.28h23.643v14.887h-38.53a14.887 14.887 0 0114.887-14.887z"
          fill="#2f2e41"
        />
        <Path
          fill="#ffb8b8"
          d="M655.599 507.783L643.339 507.783 637.506 460.495 655.601 460.496 655.599 507.783z"
        />
        <Path
          d="M634.582 504.28h23.643v14.887h-38.53a14.887 14.887 0 0114.887-14.887z"
          fill="#2f2e41"
        />
        <Path
          d="M533.098 338.67a10.743 10.743 0 014.51-15.843l41.676-114.867 20.507 11.191-47.585 109.771a10.8 10.8 0 01-19.108 9.748zM649.336 360.254a10.743 10.743 0 01-2.893-16.217l-12.91-121.51 23.337 1.067 5.366 119.52a10.8 10.8 0 01-12.9 17.14z"
          fill="#ffb8b8"
        />
        <Circle cx={612.1058} cy={162.12254} r={24.56103} fill="#ffb8b8" />
        <Path
          d="M649.18 332.619h-74.047l.089-.577c.133-.86 13.197-86.439 3.562-114.436a11.813 11.813 0 016.069-14.583c13.772-6.485 40.208-14.471 62.52 4.91a28.234 28.234 0 019.46 23.395z"
          fill="#6c63ff"
        />
        <Path
          d="M589.354 258.25l-33.336-6.763 15.626-37.03a13.997 13.997 0 0127.106 6.998zM632.05 270.808l-2.004-45.941c-1.52-8.637 3.424-16.8 11.028-18.135 7.605-1.33 15.031 4.66 16.558 13.36l7.533 42.928z"
          fill="#6c63ff"
        />
        <Path
          d="M646.716 327.119c11.915 45.376 13.214 103.069 10 166l-16-2-29-120-16 122-18-1c-5.377-66.03-10.613-122.716-2-160zM628.29 181.104c-4.583 4.88-13.092 2.26-13.69-4.407a8.055 8.055 0 01.01-1.556c.31-2.953 2.015-5.635 1.607-8.753a4.59 4.59 0 00-.84-2.15c-3.652-4.889-12.223 2.187-15.669-2.239-2.113-2.714.371-6.987-1.25-10.02-2.14-4.004-8.479-2.029-12.454-4.221-4.423-2.44-4.158-9.226-1.247-13.353 3.55-5.034 9.776-7.72 15.923-8.107s12.253 1.275 17.993 3.512c6.52 2.541 12.987 6.053 17 11.787 4.88 6.974 5.35 16.348 2.91 24.502-1.485 4.96-6.552 11.02-10.294 15.005z"
          fill="#2f2e41"
        />
        <Path
          d="M839.982 519.643H101.688a1.19 1.19 0 010-2.38h738.294a1.19 1.19 0 010 2.38z"
          fill="#3f3d56"
        />
        <Path
          d="M469 410.5H339a6.465 6.465 0 01-6.5-6.415v-290.17a6.465 6.465 0 016.5-6.415h130a6.465 6.465 0 016.5 6.415v290.17a6.465 6.465 0 01-6.5 6.415z"
          fill="#fff"
        />
        <Path
          fill="#ccc"
          d="M332.49994 201.38965H475.49994V203.38965H332.49994z"
        />
        <Path fill="#ccc" d="M332.99994 315.5H475.99994V317.5H332.99994z" />
        <Path fill="#ccc" d="M377.49994 107.5H379.49994V411.5H377.49994z" />
        <Path fill="#ccc" d="M427.49994 107.5H429.49994V411.5H427.49994z" />
      </Svg>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: 'white',
    padding: 10,
    marginTop: 15,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: 640,
    alignSelf: 'center'
  }
})

import React, { useState } from 'react'
import { View } from 'react-native'
import { CustomCard } from '../../../../components/custom/CustomCard'
import { useGetTeam } from '../../../../hooks/api/useGetTeam'
import { DataPlayerType } from './DataPlayerType'
import { Card, Button } from '@rneui/themed'
import { DataSeasonCard } from './DataSeasonCard'
import { CustomText } from './CustomText'
import { getPositionName } from './positions'

export const DataPlayerCardApi = (item) => {
  // console.log('item.item' + item.item)
  const { isLoading, team } = useGetTeam(item.item.team_id)
  const [details, setDetails] = useState(false)
  const handleClick = () => {
    setDetails(!details)
  }

  return (
    <CustomCard>
      {!isLoading && team && (
        <>
          <Card.Title>Equipo: {team.data.name}</Card.Title>
          <DataSeasonCard seasonId={item.item.season_id} />
        </>
      )}
      <CustomText>
        Posicion:{' '}
        {item.item.position_id
          ? getPositionName(item.item.position_id)
          : 'desconocida '}{' '}
        - Casaca
        {item.item.jersey_number
          ? ' N° ' + item.item.jersey_number
          : ' desconocida'}
      </CustomText>
      <CustomText> </CustomText>
      {item?.item?.details.length > 0 && (
        <Button onPress={handleClick}>Ver Detalles</Button>
      )}
      {item?.item?.details.length == 0 && (
        <CustomText>(No hay mas información)</CustomText>
      )}
      {details && (
        <>
          {item.item.details.map((typeObj, index) => (
            <View key={index} style={{ width: '100%' }}>
              <DataPlayerType typeObj={typeObj}></DataPlayerType>
            </View>
          ))}
        </>
      )}
    </CustomCard>
  )
}

import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import Svg, { Path, Circle } from 'react-native-svg'

export const SoccerSVG = (props) => {
  return (
    <View style={styles.view}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={856.97376/3}
        height={510.42687/3}
        viewBox="0 0 856.97376 510.42687"
        {...props}
      >
        <Path
          d="M414 505.065c3.119-3.939 7.232 3.896 11.346 1h-22.798c4.64 2.577 8.263-5.21 11.452-1zM488.956 506.065h-3.93c.845.593 1.692 1.184 2.498 1.825.46-.622.952-1.219 1.432-1.825z"
          fill="none"
        />
        <Path
          d="M384.301 501.338a37.624 37.624 0 00-18.293 4.727h36.54a37.46 37.46 0 00-18.247-4.727zM455.206 496.611a51.693 51.693 0 00-29.86 9.454h59.68a51.681 51.681 0 00-29.82-9.454zM856.85 436.772c-2.67-48.412-41.566-87.883-89.938-91.191a96.924 96.924 0 00-100.186 70.76c-1.174-.042-2.347-.089-3.531-.089a96.926 96.926 0 00-92.977 69.6 68.484 68.484 0 00-81.262 20.213H791.23c37.136 0 67.204-30.759 65.69-67.864q-.03-.713-.069-1.43z"
          fill="#e6e6e6"
        />
        <Path fill="#3f3d56" d="M0 505.06525H849V507.06525H0z" />
        <Path
          d="M283.344 180.239s-38.535-12.004-32.62 7.745 38.374 9.773 38.374 9.773z"
          fill="#ffb8b8"
        />
        <Path
          d="M430.646 106.114s-19.748-26.987-38.935 1.029c-19.224 28.07-37.098 51.115-37.098 51.115l-47.745 15.567-27.623 2.451 7.61 24.558 80.313-11.65s89.414-53.115 63.478-83.07z"
          fill="#2f2e41"
        />
        <Path
          d="M342.5 255.565s-30 21-28 40 15 71 15 71l27-4s3-40-7-55l48-27zM409 305.065s-2 37 3 52 10 71 10 71h28s13-49-3-71c0 0-5-29 9-43z"
          fill="#ffb8b8"
        />
        <Path
          d="M402.5 202.565s-65 41-65 54l52 43 18-20-2 30 56 11s20-76 19-79-4-47-4-47z"
          fill="#2f2e41"
        />
        <Path
          d="M356.5 357.565l-29 6 5.152 21.183a75.64 75.64 0 01.848 31.817s30 8 30 4-7-20-5-31-2-32-2-32z"
          fill="#6c63ff"
        />
        <Path
          d="M371.5 432.565c-1-4-9.308-22.135-9.654-19.068s-16.346 8.068-22.346 2.068c0 0-8-7-11 0s-9 19-9 19-27 13-23 20c.809 1.416 3.05 2.013 6.11 2.066a1.633 1.633 0 00-.11.58v.709a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.628 1.628 0 00-.18-.733 59.115 59.115 0 002.539-.28 1.63 1.63 0 00-.359 1.014v.709a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.638 1.638 0 00-.851-1.432 126.83 126.83 0 007.093-1.414 1.63 1.63 0 00-.242.847v.709a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.644 1.644 0 00-.978-1.5c1.791-.438 3.547-.893 5.218-1.344a1.63 1.63 0 00-.24.845v.709a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.64 1.64 0 00-1.157-1.563c5.872-1.654 10.157-3.082 10.157-3.082s4.976-1.81 11-4.256v.61a1.646 1.646 0 001.646 1.646h.708a1.646 1.646 0 001.646-1.645v-.71a1.636 1.636 0 00-.636-1.29 233.09 233.09 0 003.636-1.563v.563a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.642 1.642 0 00-.704-1.346 108.983 108.983 0 003.72-1.813c-.005.054-.016.105-.016.16v.709a1.646 1.646 0 001.646 1.645h.708a1.646 1.646 0 001.646-1.645v-.71a1.646 1.646 0 00-1.317-1.612c2.796-1.623 4.56-3.064 4.317-4.033z"
          fill="#2f2e41"
        />
        <Path
          d="M452 423.565l-31.68-2s1.92 33-1.92 44-1.92 15-1.92 15l27.84 2z"
          fill="#6c63ff"
        />
        <Path
          d="M452.39 496.5c-.144-4.12-4.489-23.59-5.466-20.662s-17.67 4.482-22.286-2.637c0 0-6.365-8.514-10.759-2.293s-12.763 16.706-12.763 16.706-29.117 7.084-26.665 14.764c.496 1.554 2.564 2.605 5.546 3.294a1.634 1.634 0 00-.23.545l-.147.693a1.646 1.646 0 001.266 1.953l.693.147a1.646 1.646 0 001.953-1.266l.148-.693a1.628 1.628 0 00-.023-.755c.815.1 1.664.185 2.541.254a1.63 1.63 0 00-.562.918l-.148.693a1.646 1.646 0 001.266 1.953l.693.147a1.646 1.646 0 001.953-1.266l.148-.693a1.638 1.638 0 00-.534-1.579c2.331.104 4.783.127 7.232.096a1.63 1.63 0 00-.413.778l-.148.693a1.646 1.646 0 001.266 1.953l.693.147a1.646 1.646 0 001.953-1.266l.148-.693a1.644 1.644 0 00-.644-1.673c1.843-.053 3.656-.133 5.384-.226a1.63 1.63 0 00-.411.777l-.148.693a1.646 1.646 0 001.267 1.953l.693.147a1.646 1.646 0 001.952-1.266l.148-.693a1.64 1.64 0 00-.806-1.77c6.089-.394 10.577-.897 10.577-.897s5.243-.733 11.646-1.87l-.128.598a1.646 1.646 0 001.267 1.953l.693.147a1.646 1.646 0 001.952-1.266l.148-.693a1.636 1.636 0 00-.353-1.396c1.289-.245 2.59-.501 3.882-.77l-.117.55a1.646 1.646 0 001.266 1.953l.693.148a1.646 1.646 0 001.953-1.267l.148-.693a1.642 1.642 0 00-.408-1.464c1.403-.323 2.759-.656 4.016-.997-.016.051-.037.1-.049.153l-.148.693a1.646 1.646 0 001.267 1.953l.693.147a1.646 1.646 0 001.953-1.266l.147-.693a1.646 1.646 0 00-.951-1.852c3.073-1.005 5.098-2.046 5.063-3.044z"
          fill="#2f2e41"
        />
        <Circle cx={402.5} cy={43.56525} r={29} fill="#ffb8b8" />
        <Path
          d="M428.5 40.565s16 20 24 19l-35 27s-4-17-9-19 20-27 20-27z"
          fill="#ffb8b8"
        />
        <Path
          d="M491 189.065s-.002-110.346-18.513-119.852c-18.5-9.5-30.738-14.922-30.738-14.922a25.958 25.958 0 01-9.98 18.567c-6.134 4.736-12.245 8.663-15.659 8.676l-.11-.469-.416-1.189-10.084 15.69s-9 2-12 8c-1.623 3.245 6.03 35.922 1.5 65.5-3.844 25.093-20.041 47.745-20.5 50.5-.776 4.655 18.91-7.543 27.775-13.245.132.147.225.244.225.244s-5 2 5-1c5.666-1.7 13.577-1.794 19.372-1.557-.007 1.738.034 3.118.128 4.057 1 10 51 24 58 21s6-40 6-40z"
          fill="#2f2e41"
        />
        <Path d="M456 227.065s-30 27-10 32 28-28 28-28z" fill="#ffb8b8" />
        <Path
          d="M421 297.065s14-13 22-6 13 12 13 12-23-14-35-6z"
          fill="#6c63ff"
        />
        <Path
          d="M424.636 452.614s7.891-7.328 12.4-3.382 7.328 6.763 7.328 6.763-12.964-7.89-19.728-3.381zM336.233 384.459s5.417-9.307 10.88-6.844 8.978 4.338 8.978 4.338-14.7-3.776-19.858 2.506zM483.444 82.934c-9.913-19.926-40.075-9.058-35.174 12.65q.11.486.23.981a197.351 197.351 0 0025 58l-11 49-12 25 25 6 31-75s-8.55-47.473-23.056-76.63z"
          fill="#2f2e41"
        />
        <Path
          opacity={0.2}
          d="M469 156.065L450 228.065 466 194.065 469 156.065z"
        />
        <Path
          d="M420.713 9.761s-16.03-16.876-32.166-6.248-23.79 20.952-22.207 28.141 6.264 17.188 6.264 17.188-2.91-14.893 10.45-15.995 31.82-9.944 31.82-9.944l12.485 26.664s1.765-6.179 6.66-4.407 1.1-33.422-13.306-35.399z"
          fill="#2f2e41"
        />
        <Path
          d="M318 391.065a45.906 45.906 0 01-.63 7.56v.01a45.011 45.011 0 01-62.24 33.73h-.01a43.903 43.903 0 01-5.37-2.77 45.067 45.067 0 01-20.88-29.65 45.556 45.556 0 01-.87-8.88c0-1.36.06-2.71.18-4.04v-.01a44.998 44.998 0 0189.81 3.43v.01c.01.21.01.4.01.61z"
          fill="#6c63ff"
        />
        <Path
          d="M284.077 376.035h-20.154l-10.078-17.45 6.836-11.848.4-.107a46.155 46.155 0 0126.302.735l.362.12 6.41 11.1zm-19-2h17.846l8.922-15.45-5.449-9.438a44.355 44.355 0 00-24.398-.691l-5.843 10.129zM283.077 412.036h-20.154l-10.078-17.454 10.078-17.454h20.154l10.078 17.454zm-19-2h17.846l8.922-15.454-8.922-15.454h-17.846l-8.922 15.454zM273 437.065a45.582 45.582 0 01-18.269-3.783l-1.027-.446 10.218-17.711h20.155l9.788 16.955-.972.467A45.572 45.572 0 01273 437.065zm-16.437-5.183a44.048 44.048 0 0034.485-.682l-8.125-14.075h-17.845zM317.077 394.035h-20.154l-10.078-17.45 10.077-17.46h9.178l.296.311a45.78 45.78 0 0112.594 30.996v.29zm-19-2h17.846l1.063-1.84a43.779 43.779 0 00-11.749-29.07h-7.16l-8.922 15.46zM296.645 430.547l-9.8-16.962 10.077-17.46h20.154l1.294 2.23-.014.448a46.096 46.096 0 01-20.832 31.188zm-7.49-16.962l8.175 14.148a44.09 44.09 0 0018.994-28.919l-.4-.69h-17.846zM250.106 430.978l-.873-.527a46.115 46.115 0 01-21.343-30.31l-.075-.37 2.108-3.646h20.155l10.077 17.46zm-20.18-30.862a44.112 44.112 0 0019.474 28.086l8.445-14.617-8.923-15.46h-17.845zM250.077 392.035h-20.154l-2.743-4.741v-.269a45.861 45.861 0 0114.489-29.633l.288-.267h8.12l10.078 17.46zm-19-2h17.846l8.922-15.45-8.923-15.46h-6.176a43.818 43.818 0 00-13.541 27.674z"
          fill="#fff"
        />
      </Svg>
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: 'white',
    padding: 10,
    marginTop: 15,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: 640,
    alignSelf: 'center'
  }
})

import React from 'react'
import { StyleSheet } from 'react-native'
import { Avatar, Text, Card, ListItem } from '@rneui/themed'
import { CustomCard } from '../custom/CustomCard'

export const PlayerListCard = ({
  isApi,
  list,
  position,
  posicion,
  position_id,
  handleNavigateToPlayer,
}) => {
  const p = isApi
    ? list?.filter((item) => item.position_id === position_id)
    : list.filter((item) => item.statistics[0].games.position === position)

  return (
    <CustomCard>
      <Card.Title style={styles.title}>{posicion}s</Card.Title>
      {p.map((item, index) => (
        <ListItem
          key={index}
          bottomDivider
          onPress={() => handleNavigateToPlayer(item)}
        >
          <Avatar
            source={{ uri: isApi ? item.player.image_path : item.player.photo }}
          />
          <ListItem.Content>
            <ListItem.Title>
              {isApi ? item.player.common_name : item.player.name}
            </ListItem.Title>
            <ListItem.Subtitle>
              {item.player.firstname} {item.player.lastname}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
      ))}
      {p.length === 0 && (
        <Text> No se encontraron {posicion.toLowerCase()}s</Text>
      )}
    </CustomCard>
  )
}

const styles = StyleSheet.create({
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  imageView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 200,
    margin: 25,
    marginTop: 10,
    borderRadius: 5,
  },
  text: {
    textAlign: 'center',
    lineHeight: 25,
  },
})

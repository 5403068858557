import React, { useState } from 'react'
import { StyleSheet, Image } from 'react-native'
import { useMessageStore } from '../../../redux/hooks/useMessage'
import { useGetTeam } from '../../../hooks/api/useGetTeam'
import { useGetPlayers } from '../../../hooks/api/useGetPlayers'
import { ClubScreen } from '../../../components/club/ClubScreen'

export const ClubApi = ({ navigation, route }) => {
  const { club, setPlayer } = useMessageStore()
  const teamId = route.params.id
  const { team } = useGetTeam(teamId)
  const { players } = useGetPlayers(teamId)

  const config = {
    title: 'ClubApi',
    api: true,
    playerList: players.data ?? [],
    team: {
      name: team?.data?.name ?? 'Desconocido',
      logo: team?.data?.image_path ?? undefined,
    },
    venue: {
      id: team?.data?.venue_id ?? undefined,
    },
  }

  const handleNavigateToPlayer = (item) => {
    setPlayer(item.player.id)
    navigation.navigate('PlayerApi', { id: item.player.id })
  }

  return (
    <ClubScreen
      handleNavigateToPlayer={handleNavigateToPlayer}
      config={config}
    />
  )
}

const styles = StyleSheet.create({
  card: {
    borderColor: '#2089dc55',
    borderRadius: 5,
  },
  cardWrapper: {},
  text: {
    textAlign: 'center',
    lineHeight: 25,
  },
  estadioFoto: {
    width: 200,
    height: 200,
    margin: 25,
    marginTop: 10,
    borderRadius: 5,
  },
  escudoFoto: { width: 100, height: 100, margin: 10 },
  mainView: {
    flex: 1,
  },
  view: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewTop: { marginTop: 25 },
  viewBottom: { flex: 1 },
  icon: { padding: 5 },
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  cardTitle: {
    marginTop: 20,
    alignSelf: 'center',
  },
})

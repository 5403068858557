import React, { useState } from 'react'
import { Image } from 'react-native'
import { ClubSkeleton } from './ClubSkeleton'
import { LogoClubCard } from './LogoClubCard'
import { StadiumClubCard } from './StadiumClubCard'
import { StadiumClubCardApi } from './StadiumClubCardApi'
import { PlayerListCard } from '../player/PlayerListCard'
import { Header } from '../layout/Header'
import { CustomScrollView } from '../custom/CustomScrollView'
import { useIsImageLoaded } from '../../hooks/images/useIsImageLoaded'

export const ClubScreen = ({ config, handleNavigateToPlayer }) => {
  const { isImageLoaded, isTimeOut, handleImageLoaded, handleImageError } =
    useIsImageLoaded()

  return (
    <CustomScrollView>
      {isImageLoaded && (
        <>
          <Header title={config.title} />
          {config?.team && (
            <LogoClubCard name={config.team.name} logo={config.team?.logo} />
          )}
          {!config.api && (
            <StadiumClubCard
              name={config.venue.name}
              city={config.venue.city}
              image={config.venue.image}
              capacity={config.venue.capacity}
              surface={config.venue.surface}
            />
          )}
          {config.api && config?.venue?.id && (
            <StadiumClubCardApi id={config.venue.id} />
          )}
          <PlayerListCard
            isApi={config.api}
            list={config.playerList}
            position="Goalkeeper" // Json
            posicion="Portero" // Json
            position_id={24} // Api
            handleNavigateToPlayer={handleNavigateToPlayer}
          />
          <PlayerListCard
            isApi={config.api}
            list={config.playerList}
            position="Defender"
            posicion="Defensa"
            position_id={25}
            handleNavigateToPlayer={handleNavigateToPlayer}
          />
          <PlayerListCard
            isApi={config.api}
            list={config.playerList}
            position="Midfielder"
            posicion="Mediocampista"
            position_id={26}
            handleNavigateToPlayer={handleNavigateToPlayer}
          />
          <PlayerListCard
            isApi={config.api}
            list={config.playerList}
            position="Attacker"
            posicion="Atacante"
            position_id={27}
            handleNavigateToPlayer={handleNavigateToPlayer}
          />
        </>
      )}
      <>
        {!isImageLoaded && <ClubSkeleton ancho="98%" />}
        <Image
          style={{ width: 1, height: 1, marginTop: 20 }}
          source={{ uri: config.team?.logo }}
          onLoadEnd={handleImageLoaded}
          onError={handleImageError}
        />
      </>
    </CustomScrollView>
  )
}

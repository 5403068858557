import React from 'react'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { useExplorePlayerWithStoreByID } from '../../hooks/players/useExplorePlayerWithStoreByID'
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { PhotoPlayerCard } from './PhotoPlayerCard'
import { DataPlayerCard } from './DataPlayerCard'
import { DataPlayerCardEditor } from './DataPlayerCardEditor'

const config = {
  title: 'MyPlayerProfile',
}

export const MyProfilePlayer = ({ navigation, route }) => {
  const { player, playerListFiltered } = useExplorePlayerWithStoreByID(
    route.params.id,
  )

  const handleNavigateToProfile = () => {
    navigation.navigate('Profile')
  }

  return (
    <CustomScrollView>
      <Header title={config.title} />
      <PhotoPlayerCard id={player.id} name={player.name} photo={player.photo} />
      {player && route.params.edit && (
        <DataPlayerCardEditor
          item={player}
          itemOriginal={playerListFiltered[0]}
          handleNavigateToProfile={handleNavigateToProfile}
        />
      )}
      {player && !route.params.edit && <DataPlayerCard item={player} />}
    </CustomScrollView>
  )
}

import { useState, useEffect } from 'react'
import { getPlayers, getPlayerListByTeamId } from '../../_json/api.player'
import { getTeams, getTeamById } from '../../_json/api.team'

export const useExploreByID = (id) => {
  const [isLoading, SetIsLoading] = useState(true)
  const [teamList, setTeamList] = useState([])
  const [playerList, setPlayerList] = useState([])

  useEffect(() => {
    setTeamList(getTeams())
    setPlayerList(getPlayers())
    SetIsLoading(false)
  }, [])

  const teamListFiltered = getTeamById(id)
  const playerListFiltered = getPlayerListByTeamId(id)

  return {
    isLoading,
    teamList,
    playerList,
    teamListFiltered,
    playerListFiltered
  }
}

import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Text, Card } from '@rneui/themed'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useGetVenue } from '../../hooks/api/useGetVenue'
import { CustomCard } from '../custom/CustomCard'
import { FadeInView } from '../effect/FadeInView'
import { useIsImageLoaded } from '../../hooks/images/useIsImageLoaded'

export const StadiumClubCardApi = ({ id }) => {
  const { venue, isLoading } = useGetVenue(id)
  const { isImageLoaded, isTimeOut, handleImageLoaded, handleImageError } =
    useIsImageLoaded()

  return (
    <>
      {isImageLoaded && (
        <CustomCard>
          <Card.Title style={styles.title}>Estadio</Card.Title>
          {!isTimeOut && (
            <FadeInView style={{ flex: 1 }} update={isImageLoaded}>
              <View style={styles.imageView}>
                <Card.Image
                  style={styles.image}
                  source={{ uri: venue?.image_path }}
                />
              </View>
            </FadeInView>
          )}
          <View style={styles.view}>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="football-outline"
                color="green"
                size={16}
              />
              {'  '}Estadio: {venue?.name}
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="earth-outline"
                color="green"
                size={16}
              />
              {'  '}Ciudad: {venue?.city_name}
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="people-outline"
                color="green"
                size={16}
              />
              {'  '}Capacidad: {venue?.capacity} personas
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="planet-outline"
                color="green"
                size={16}
              />
              {'  '}Superficie: {venue?.surface}
            </Text>
            <Text style={styles.text}>
              <Ionicons
                style={styles.icon}
                name="planet-outline"
                color="green"
                size={16}
              />
              {'  '}Direccion: {venue?.address}
            </Text>
            {/* <Text style={{ padding: 10 }}></Text> */}
          </View>
        </CustomCard>
      )}
      <>
        {!isImageLoaded && (
          <CustomCard>
            <Card.Title style={styles.title}>Estadio</Card.Title>
            <ActivityIndicator color="rgba(251,133,0,1)" size={150} />
          </CustomCard>
        )}
        <Card.Image
          style={{ width: 1, height: 1 }}
          source={{ uri: venue?.image_path }}
          onLoadEnd={handleImageLoaded}
          onError={handleImageError}
        />
      </>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderColor: '#2089dc55',
    borderRadius: 5,
  },
  wrapper: {},
  iconFavorite: {
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  imageView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 200,
    margin: 25,
    marginTop: 10,
    borderRadius: 5,
  },
  text: {
    textAlign: 'center',
    lineHeight: 25,
  },
})

import React from 'react'
import { useMessageStore } from '../../redux/hooks/useMessage'
import { useExplorePlayerByID } from '../../hooks/players/useExplorePlayerByID'
import { Header } from '../../components/layout/Header'
import { CustomScrollView } from '../../components/custom/CustomScrollView'
import { PhotoPlayerCard } from '../Explore/Player/PhotoPlayerCard/PhotoPlayerCard'
import { LogoClubCard } from '../../components/club/LogoClubCard'
import { DataPlayerCard } from '../Explore/Player/DataPlayerCard/DataPlayerCard'

const config = {
  title: 'MyPlayer',
}
export const MyPlayer = () => {
  const { myPlayer: playerID } = useMessageStore()
  const { player } = useExplorePlayerByID(playerID)

  return (
    <>
      <CustomScrollView>
        <Header title={config.title} />
        <PhotoPlayerCard
          id={playerID}
          name={player.name}
          photo={player.photo}
        />
        <LogoClubCard name={player.club} logo={player.logo} />
        <DataPlayerCard player={player} />
      </CustomScrollView>
    </>
  )
}

import { useState, useEffect } from 'react'
import { getAllFavoritesService } from '../../_storage-favorites/storage.services'
import { setItem } from '../../helpers/storageHelper'

export const FAVORITES_KEY = '@storage_favorites'
export const FAVORITES_KEY_JSON = FAVORITES_KEY
export const FAVORITES_KEY_API = '@storage_favorites_api'

export const useFavorites = (favoritesCounter, key) => {
  const [isLoading, setIsLoading] = useState(true)
  const [favorites, setFavorites] = useState([])

  const addFavorite = async (id, key) => {
    await setItem(id, 'add', key)
  }

  const removeFavorite = async (id, key) => {
    await setItem(id, 'remove', key)
  }

  useEffect(() => {
    const getAll = async () => {
      var data = await getAllFavoritesService(key)
      setFavorites(data)
      setIsLoading(false)
    }
    getAll()
  }, [favoritesCounter])

  return { isLoading, favorites, addFavorite, removeFavorite }
}
